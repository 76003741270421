/**
 * CondensedFormula is a condensed representation of a Formula.
 * It is used to display a list of formulas in the search results, and on the home page inside the label groups.
 */
type CondensedFormula = {
  ident: string
  name: string
  docstring: string
  keywords: string[]
}

namespace CondensedFormula {
  /**
   * Checks if the given JSON object is a valid CondensedFormula.
   * @param json The JSON object to check.
   * @throws SyntaxError if the given JSON object is not a valid CondensedFormula.
   */
  export function isCondensedFormula(json: unknown): asserts json is CondensedFormula {
    if (typeof json !== 'object' || json === null) {
      throw SyntaxError('bad CondensedFormula format: not an object')
    }

    if (!('ident' in json)) {
      throw SyntaxError('bad CondensedFormula format: missing property ident')
    }

    if (typeof json.ident !== 'string') {
      throw SyntaxError('bad CondensedFormula format: property ident is not a string')
    }

    if (!('name' in json)) {
      throw SyntaxError('bad CondensedFormula format: missing property name')
    }

    if (typeof json.name !== 'string') {
      throw SyntaxError('bad CondensedFormula format: property name is not a string')
    }

    if (!('keywords' in json)) {
      throw SyntaxError('bad CondensedFormula format: missing property keywords')
    }

    if (!Array.isArray(json.keywords)) {
      throw SyntaxError('bad CondensedFormula format: property keywords is not an array')
    }

    if (!json.keywords.every(keyword => typeof keyword === 'string')) {
      throw SyntaxError('bad CondensedFormula format: property keywords contains non-string values')
    }

    if (!('docstring' in json)) {
      throw SyntaxError('bad CondensedFormula format: missing property docstring')
    }

    if (typeof json.docstring !== 'string') {
      throw SyntaxError('bad CondensedFormula format: property docstring is not a string')
    }
  }

  /**
   * Creates a CondensedFormula from the given JSON object.
   * @param json The JSON object to load from.
   * @throws SyntaxError if the given JSON object is not a valid CondensedFormula.
   * @returns The created CondensedFormula.
   */
  export function fromJson(json: unknown): CondensedFormula {
    isCondensedFormula(json)
    return json
  }
}

export default CondensedFormula
