import { useEffect, useState } from 'react'

/**
 * A useState-style hook that persists in browser storage.
 * Wrapper around useState that persists the value in browser storage.
 *
 * @param defaultValue The default value to use if the key is not set.
 * @param key The key to use for storage.
 * @param storage The storage to use. Defaults to localStorage.
 * @returns A tuple containing the value and a function to set the value.
 */
export default function useStickyState<T>(
  defaultValue: T,
  key: string,
  storage = localStorage
): [T, React.Dispatch<React.SetStateAction<T>>] {
  // The wrapped useState hook.
  const [value, setValue] = useState<T>(() => {
    // Get the sticky value, or the default value if it doesn't exist.
    try {
      const stickyValue = storage.getItem(key)
      return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue
    } catch (err) {
      return defaultValue
    }
  })

  // Update the sticky value when the value changes.
  useEffect(() => storage.setItem(key, JSON.stringify(value)), [key, value])

  return [value, setValue]
}
