import styled from 'styled-components'
import { ThemeColor } from 'themes'

const Button = styled.button`
  background-color: var(${ThemeColor.Secondary});
  color: var(${ThemeColor.Text});
  border: none;
  cursor: pointer;
  margin: auto 0;
  padding: 8px 16px;
  border-radius: 10px;
  flex-grow: 1;
  transition: color 0.2s, background-color 0.2s;

  :hover {
    background-color: var(${ThemeColor.Tertiary});
  }
`

export default Button
