/**
 * Utility class to provide a Promise based API for WebWorker communication.
 * This is needed to await a response from the WebWorker on the main thread.
 * The DeferredPromise can be manually resolved or rejected, based on the response from the WebWorker.
 */
export class DeferredPromise<T> {
  _promise: Promise<T>
  resolve: (value: T | PromiseLike<T>) => void
  reject: (value: T | PromiseLike<T>) => void

  /**
   * Creates a DeferredPromise.
   */
  constructor() {
    this.resolve = () => undefined
    this.reject = () => undefined

    this._promise = new Promise(
      ((resolve: (value: T | PromiseLike<T>) => void, reject: (value: T | PromiseLike<T>) => void) => {
        this.resolve = resolve
        this.reject = reject
      }).bind(this)
    )
  }
}

/**
 * Utility class that wraps a WebWorker and provides a Promise based API for requesting work from the WebWorker.
 */
class WebWorkerWrapper<WorkRequestType> {
  promiseMap: Map<string, DeferredPromise<unknown>>
  worker: Worker

  /**
   * Creates a WebWorkerWrapper.
   * Incoming messages will be handled automatically.
   * The WebWorker must send a response with the same ID as the request.
   *
   * @param worker The WebWorker to wrap.
   */
  constructor(worker: Worker) {
    this.promiseMap = new Map()
    this.worker = worker

    this.worker.onmessage = this.handleMessage.bind(this)
  }

  /**
   * Request the WebWorker to perform a request.
   *
   * @param workRequest The request to send to the WebWorker.
   */
  perform(workRequest: WorkRequestType) {
    const uniqueId = Date.now() + '-' + Math.floor(Math.random() * 1000000)
    const deferredPromise = new DeferredPromise()

    this.promiseMap.set(uniqueId, deferredPromise)

    this.worker.postMessage({ id: uniqueId, ...workRequest })

    return deferredPromise._promise
  }

  /**
   * Handles incoming messages from the WebWorker.
   * The WebWorker must send a response with the same ID as was given in the request.
   * If the WebWorker sends an error, the promise will be rejected.
   *
   * @param event The incoming message event.
   */
  private handleMessage(event: MessageEvent<{ id: string; [key: string]: unknown }>) {
    if ('error' in event.data) {
      const { id, error } = event.data
      this.promiseMap.get(id)?.reject(error)
      this.promiseMap.delete(id)
      return
    }

    const { id, data } = event.data

    this.promiseMap.get(id)?.resolve(data)
    this.promiseMap.delete(id)
  }
}

export default WebWorkerWrapper
