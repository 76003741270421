import Card from 'components/Card'
import config from 'config'
import CondensedFormula from 'domain/entities/CondensedFormula'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { LoaderFunction, useLoaderData } from 'react-router-dom'
import styled from 'styled-components'
import { ThemeColor } from 'themes'

const Formulas = styled.div`
  max-width: 1100px;
  width: 100%;
  margin: 100px auto 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: var(${ThemeColor.TextSecondary});
  text-align: center;
  font-size: 1.2rem;
  transition: color 0.2s;
`

/**
 * Loads the search results from the API.
 *
 * @param request The request object.
 */
export const loader = (async ({ request }) => {
  const url = new URL(request.url)
  const searchQuery = url.searchParams.get('q')
  const fetched = await (await fetch(`${config.apiUrl}/search?q=${searchQuery}`)).json()

  if (!Array.isArray(fetched)) {
    return { formulas: [], q: searchQuery ? searchQuery : undefined }
  }

  return { formulas: fetched.map(f => CondensedFormula.fromJson(f)), q: searchQuery ? searchQuery : undefined }
}) satisfies LoaderFunction

/**
 * The Search view.
 *
 * The search page displays the results of a search. It displays a list of formulas
 * that match the search query. The formulas are sorted by relevance, which is
 * determined by the search heuristic.
 */
export default function Search() {
  const { formulas, q } = useLoaderData() as LoaderData<typeof loader>
  const { t } = useTranslation()

  useEffect(() => {
    document.title = `${q} - ${t('search.title')}`
  }, [q])

  return (
    <Formulas>
      {formulas.length > 0 ? (
        formulas.map(formula => (
          <Card ident={formula.ident} name={formula.name} key={formula.ident} docstring={formula.docstring} />
        ))
      ) : (
        <p>{t('search.no_results', { query: q })}</p>
      )}
    </Formulas>
  )
}
