import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-router-dom'

/**
 * Search bar component.
 *
 * @param props.defaultValue Default value for the search bar.
 * @param props.className Class name for the search bar.
 */
export default function SearchBar(props: { defaultValue?: string; className?: string }) {
  const [value, setValue] = useState(props.defaultValue)
  const { t } = useTranslation()

  return (
    <Form
      className={props.className}
      role='search'
      action='/search'
      onSubmit={e => {
        if (value === undefined || value === '') e.preventDefault()
      }}
    >
      <input
        aria-label={t('search.field')}
        placeholder={t('search.field')}
        type='search'
        name='q'
        value={value}
        onChange={e => setValue(e.target.value)}
        data-testid='search-input'
      />
    </Form>
  )
}
