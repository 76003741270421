import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { useRef, useState } from 'react'
import styled from 'styled-components'
import { ThemeColor } from 'themes'
import ClickIcon from './ClickIcon'
import Modal from './Modal'

const TooltipIcon = styled(ClickIcon)<{ iconStyle?: string }>`
  align-self: center;
  justify-self: center;
  ${props => props.iconStyle}
`

export const TooltipPopup = styled.dialog`
  position: absolute;
  top: -10px;
  transform: translateY(-100%);
  padding: 8px 16px;
  z-index: 1000000;
  background: var(${ThemeColor.Secondary});
  color: var(${ThemeColor.TextSecondary});
  border: 1px solid var(${ThemeColor.Tertiary});
  border-radius: 10px;
  text-align: center;
  white-space: pre-line;
  width: max-content;
  max-width: calc(100vw - 60px);
  transition: color 0.2s, background-color 0.2s, border-color 0.2s;
`

export const TooltipModal = styled(Modal)`
  white-space: pre-line;
`

export default function Tooltip(props: {
  children: React.ReactNode
  testId?: string
  // for styled components
  className?: string
}) {
  const [popUp, setPopUp] = useState(false) // haha get it because it controls when the popup """pops up"""
  const modalRef = useRef<HTMLDialogElement>(null)

  return (
    <>
      <TooltipIcon
        icon={faQuestionCircle}
        onMouseEnter={() => setPopUp(true)}
        onMouseLeave={() => setPopUp(false)}
        onClick={() => modalRef.current?.showModal()}
        onKeyDown={ev => ev.key === 'Enter' && modalRef.current?.showModal()}
        data-testid={props.testId && `tooltip-${props.testId}`}
        className={props.className}
        tabIndex={0}
        aria-label='Tooltip'
      />

      <TooltipPopup open={popUp} data-testid={props.testId && `tooltip-popup-${props.testId}`}>
        {props.children}
      </TooltipPopup>

      <TooltipModal ref={modalRef} data-testid={props.testId && `tooltip-modal-${props.testId}`}>
        {props.children}
      </TooltipModal>
    </>
  )
}
