import logoTUBlack from 'assets/img/TUDelftRGB.svg'
import logoTUWhite from 'assets/img/TUDelftWhite.svg'
import config from 'config'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ThemeColor, ThemeContext } from 'themes'

const FooterBox = styled.div`
  width: 100vw;
  position: relative;
  display: grid;
  grid-template-columns: 1fr min(1100px, calc(100% - 60px)) 1fr;
  grid-template-rows: 1fr auto;
  gap: 20px;
  padding: 40px 0 30px;
  background: var(${ThemeColor.Secondary});
  transition: background-color 0.2s;

  & > * {
    grid-column: 2;
  }
`

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`

const TUDelftLogo = styled.img`
  width: max-content;
  height: 3rem;
`

const SourceLink = styled.a`
  display: flex;
  gap: 7px;
  grid-column: 2;
  transform: translateX(-100%);
  transform: translateY(40%);
  font-size: 1.2rem;
  color: var(${ThemeColor.Text});
  transition: color 0.2s;
`

const Disclaimer = styled.div`
  width: 100%;
  color: var(${ThemeColor.TextSecondary});
  text-align: justify;
  font-size: 0.8rem;
  transition: color 0.2s;
`

/**
 * The footer component of the website.
 */
export default function Footer() {
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()

  return (
    <FooterBox>
      <Content>
        <TUDelftLogo src={theme === 'light' ? logoTUBlack : logoTUWhite} alt={t('footer.tudelft_logo')} />
        <SourceLink href={config.projectUrl} target='_blank' rel='noreferrer noopener'>
          {t('footer.source_code')}
        </SourceLink>
      </Content>
      <Disclaimer>{t('footer.disclaimer')}</Disclaimer>
    </FooterBox>
  )
}
