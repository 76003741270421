import { faCode, faShareFromSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import breakpoints from 'breakpoints'
import Checkbox from 'components/Checkbox'
import ClickIcon from 'components/ClickIcon'
import { Label, SingleField } from 'components/Field'
import Modal, { ModalRef } from 'components/Modal'
import Separator from 'components/Separator'
import Table from 'components/Table'
import config from 'config'
import FormulaInfo from 'domain/entities/Formula'
import pyodideService from 'domain/services/PyodideServiceProxy'
import i18n from 'i18n'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Markdown from 'react-markdown'
import { LoaderFunction, Outlet, useLoaderData } from 'react-router-dom'
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter'
import markdown from 'react-syntax-highlighter/dist/esm/languages/hljs/markdown'
import python from 'react-syntax-highlighter/dist/esm/languages/hljs/python'
import rehypeKatex from 'rehype-katex'
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'
import styled from 'styled-components'
import { Theme, ThemeColor, ThemeContext, codeThemes } from 'themes'

SyntaxHighlighter.registerLanguage('python', python)
SyntaxHighlighter.registerLanguage('md', markdown)

export const FormulaHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 100px 0 20px;
  color: var(${ThemeColor.Text});
  text-align: center;
  font-weight: bold;
  transition: color 0.2s;

  @media ${breakpoints.md} {
    flex-direction: column;
  }
`

const FormulaTitle = styled.h3`
  font-size: 3rem;
`

const TitleButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  border: 1px solid var(${ThemeColor.Tertiary});
  border-radius: 10px;
  overflow: hidden;
  transition: border-color 0.2s;

  & > :not(:last-child) {
    border-right: 1px solid var(${ThemeColor.Tertiary});
  }
`

const TitleButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  background-color: var(${ThemeColor.Secondary});
  color: var(${ThemeColor.TextSecondary});
  font-size: 1.2rem;
  font-weight: normal;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.2s, background-color 0.2s;

  &:hover {
    color: var(${ThemeColor.Accent});
    background-color: var(${ThemeColor.Tertiary});
  }
`

const TestButton = styled(ClickIcon)`
  display: inline-flex;
  padding-left: 1rem;
  font-size: 1.2rem;
`

const Documentation = styled.div`
  display: grid;
  grid-template-columns: 1fr min(60ch, calc(100% - 60px)) 1fr;
  gap: 20px 32px;
  text-align: justify;
  color: var(${ThemeColor.Text});
  font-size: 1.2rem;
  padding-top: 20px;
  transition: color 0.2s;

  & > * {
    grid-column: 2;
    margin: 0 !important;
  }
`

const DocLink = styled.a`
  color: var(${ThemeColor.Text});
  font-weight: 500;
  transition: color 0.2s;

  :hover {
    color: var(${ThemeColor.Accent});
  }
`

const DocQuote = styled.blockquote`
  padding: 0 1rem;
  margin-top: 0;
  margin-bottom: 48px;
  border-left: 5px solid #ffffff20;
  background: var(${ThemeColor.Secondary});
  transition: background-color 0.2s;
`

const CodeBlock = styled.pre`
  color: var(${ThemeColor.TextSecondary});
  background: var(${ThemeColor.Secondary});
  padding: 2px 8px;
  border-radius: 10px;
  border: 1px solid var(${ThemeColor.Tertiary});
  overflow-x: auto;
  width: 100%;
  max-width: 1100px;
  transition: color 0.2s, background-color 0.2s, border-color 0.2s;

  & code {
    padding: 0;
  }

  ${DocQuote} & {
    background: var(${ThemeColor.Primary});
  }
`

const TestsTitle = styled.h2`
  text-align: center;
`

const CopyUrl = styled(CodeBlock)`
  max-width: min(1100px, 100%);
  cursor: pointer;
  user-select: none;

  & code {
    padding: 4px 8px;
  }

  &:hover {
    background: var(${ThemeColor.Tertiary});
  }
`

const EmbedFields = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;

  @media ${breakpoints.md} {
    flex-direction: column;
  }

  & > * {
    flex-grow: 1;
  }
`

const EmbedField = styled(SingleField)`
  & > ${Label} {
    background-color: var(${ThemeColor.Primary});
  }
`

const EmbedCheckbox = styled(Checkbox)`
  background-color: var(${ThemeColor.Primary});
`

function createEmbedLink(width: string, height: string, theme?: Theme, fillFields = false) {
  const searchParams = fillFields ? new URLSearchParams(window.location.search) : new URLSearchParams()
  if (theme) searchParams.set('theme', theme)
  const formulaPath = window.location.pathname.split('/').slice(2).join('/')
  const embedSource = `${window.location.origin}/embed/${formulaPath}${
    searchParams.size > 0 ? '?' : ''
  }${searchParams.toString()}`
  return `<iframe src="${embedSource}"${width && ` width="${width}"`}${height && ` height="${height}"`}></iframe>`
}

/**
 * Loads the formula metadata and script from the API.
 * Then creates a Pyodide execution environment for the formula.
 *
 * @param params The route parameters.
 * @returns The formula and the Pyodide execution environment.
 */
export const loader = (async ({ params }: { params: { id?: string } }) => {
  if (!params.id) throw new URIError(i18n.t('error.no_formula_id'))

  // Fetch the formula metadata and script from the API.
  const [metadata, script] = await Promise.all([
    (await fetch(`${config.apiUrl}/formula/${params.id}`)).json(),
    (await fetch(`${config.apiUrl}/formula/script/${params.id}`)).text(),
  ])

  // Create the Pyodide execution environment for the formula.
  const formula = FormulaInfo.fromJson(metadata, script)
  const environment = pyodideService.createExecutionEnvironment(formula)

  return { environment, formula }
}) satisfies LoaderFunction

/**
 * Formula view.
 *
 * Consists of the formula name, formula documentation, and tests.
 * The Fields or Graph view is rendered in the outlet.
 */
export default function Formula() {
  const { environment, formula } = useLoaderData() as LoaderData<typeof loader>
  const { t } = useTranslation()
  const testDialogRef = useRef<ModalRef>(null)
  const shareDialogRef = useRef<ModalRef>(null)
  const themeContext = useContext(ThemeContext)
  const [embedDimensions, setEmbedDimensions] = useState({ width: '800', height: '600' })
  const [embedTheme, setEmbedTheme] = useState<'none' | Theme>('none')
  const [embedFillFields, setEmbedFillFields] = useState(false)

  useEffect(() => {
    document.title = `${formula.name} - PyBox`
  }, [])

  return (
    <>
      <FormulaHeader>
        <FormulaTitle>{formula.name}</FormulaTitle>
        <TitleButtons>
          <TitleButton
            as='a'
            data-testid='title-link'
            href={new URL(formula.notebookPath, config.repositoryBaseUrl).href}
            target='_blank'
          >
            <FontAwesomeIcon icon={faCode} />
            Source code
          </TitleButton>
          <TitleButton
            data-testid='title-share'
            onClick={() => shareDialogRef.current?.showModal()}
            tabIndex={0}
            aria-label='Share'
          >
            <FontAwesomeIcon icon={faShareFromSquare} />
            Share
          </TitleButton>
        </TitleButtons>
      </FormulaHeader>
      {/* Fields or Graph view goes here */}
      <Outlet context={{ environment, formula }} />
      <Documentation>
        <Markdown
          remarkPlugins={[remarkMath, remarkGfm]}
          rehypePlugins={[rehypeKatex]}
          components={{
            code({ inline, className, children, ...props }) {
              // Render code blocks with syntax highlighting.
              const match = /language-(\w+)/.exec(className || '')
              return !inline && match ? (
                <SyntaxHighlighter
                  {...props}
                  style={codeThemes[themeContext]}
                  language={match[1]}
                  PreTag='div'
                  customStyle={{
                    backgroundColor: 'transparent',
                  }}
                >
                  {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
              ) : (
                <code {...props} className={className}>
                  {children}
                </code>
              )
            },
            pre: props => <CodeBlock {...props} />,
            table: props => <Table {...props} />,
            a: props => <DocLink {...props} />,
            blockquote: props => <DocQuote {...props} />,
            hr: props => <Separator {...props} />,
          }}
        >
          {formula.documentation}
        </Markdown>
        <Separator />
        <TestsTitle>
          {t('formula.test', { count: formula.testCases.length })}
          {formula.testSource && <TestButton icon={faCode} onClick={() => testDialogRef.current?.showModal()} />}
        </TestsTitle>
        {formula.testCases.map((testCase, i) => (
          <Table key={i}>
            <thead>
              <tr>
                <th colSpan={Object.keys(testCase.inputs).length}>
                  {t('formula.input', { count: Object.keys(testCase.inputs).length })}
                </th>
                <th colSpan={Object.keys(testCase.outputs[0]).length}>
                  {t('formula.output', { count: Object.keys(testCase.outputs[0]).length })}
                </th>
              </tr>
              <tr>
                {Object.keys(testCase.inputs).map((input, i) => (
                  <td key={i}>{input}</td>
                ))}
                {Object.keys(testCase.outputs[0]).map((output, i) => (
                  <td key={i}>{output}</td>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {Object.values(testCase.inputs).map((input, i) => (
                  <td key={i} rowSpan={testCase.outputs.length}>
                    {input}
                  </td>
                ))}
                {Object.values(testCase.outputs[0]).map((output, i) => (
                  <td key={i}>{output}</td>
                ))}
              </tr>
              {testCase.outputs.slice(1).map((outs, i) => (
                <tr key={i}>
                  {Object.values(outs).map((output, i) => (
                    <td key={i}>{output}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        ))}
      </Documentation>
      <Modal title={t('formula.test_source')} ref={testDialogRef}>
        {formula.testSource ? (
          <SyntaxHighlighter
            style={codeThemes[themeContext]}
            language='python'
            customStyle={{
              borderRadius: '10px',
              padding: '2rem',
            }}
            showLineNumbers
          >
            {formula.testSource}
          </SyntaxHighlighter>
        ) : (
          t('formula.no_test_source')
        )}
      </Modal>
      <Modal title={t('formula.share')} ref={shareDialogRef}>
        <Separator text='Link' background={ThemeColor.Secondary} />
        {t('formula.copy_link')}
        <CopyUrl onClick={() => navigator.clipboard.writeText(window.location.href)}>
          <code>{window.location.href}</code>
        </CopyUrl>
        <Separator text='Embed' background={ThemeColor.Secondary} />
        {t('formula.copy_embed')}
        <CopyUrl
          onClick={() =>
            navigator.clipboard.writeText(
              createEmbedLink(
                embedDimensions.width,
                embedDimensions.height,
                embedTheme === 'none' ? undefined : embedTheme,
                embedFillFields
              )
            )
          }
        >
          <code>
            {createEmbedLink(
              embedDimensions.width,
              embedDimensions.height,
              embedTheme === 'none' ? undefined : embedTheme,
              embedFillFields
            )}
          </code>
        </CopyUrl>
        <EmbedFields>
          <EmbedField
            type='text'
            label={t('formula.width')}
            value={embedDimensions.width}
            format={/^[0-9]*$/}
            onChange={ev => setEmbedDimensions({ ...embedDimensions, width: ev.target.value })}
          />
          <EmbedField
            type='text'
            label={t('formula.height')}
            value={embedDimensions.height}
            format={/^[0-9]*$/}
            onChange={ev => setEmbedDimensions({ ...embedDimensions, height: ev.target.value })}
          />
          <EmbedField
            type='dropdown'
            label={t('formula.theme')}
            options={[
              { value: 'none', name: t('formula.no_theme') },
              { value: 'light', name: t('formula.light_theme') },
              { value: 'dark', name: t('formula.dark_theme') },
            ]}
            value={embedTheme}
            onChange={value => setEmbedTheme(value as 'none' | Theme)}
          />
          <EmbedCheckbox
            label={t('formula.fill_fields')}
            checked={embedFillFields}
            onChange={setEmbedFillFields}
            id='embed-fill-fields'
          />
        </EmbedFields>
      </Modal>
    </>
  )
}
