import { faSliders } from '@fortawesome/free-solid-svg-icons'
import breakpoints from 'breakpoints'
import ChoiceEntry from 'domain/entities/ChoiceEntry'
import { ConstantValue } from 'domain/entities/GraphSettings'
import { ValueTypes } from 'domain/entities/ValueType'
import { ChangeEvent, ChangeEventHandler, ReactNode } from 'react'
import styled from 'styled-components'
import { ThemeColor } from 'themes'
import { computeDefaultBounds, truncate } from 'util/numberUtils'
import ClickIcon from './ClickIcon'
import Tooltip, { TooltipModal, TooltipPopup } from './Tooltip'

export const TextBox = styled.input<{ align?: 'left' | 'right' | 'center'; forceWidth?: boolean }>`
  min-width: 0;
  padding: 8px 16px;
  margin: 0;
  outline: none;
  border: none;
  text-align: ${props => props.align ?? 'left'};
  color: var(${ThemeColor.Text});
  background: var(${ThemeColor.Tertiary});
  transition: color 0.2s, background-color 0.2s;

  ${props => props.forceWidth && `min-width: 70px;`}

  @media ${breakpoints.sm} {
    ${props => props.forceWidth && `min-width: 100%;`}
    text-align: center;
  }
`

export const Label = styled.div<{ align?: 'left' | 'right' | 'center' }>`
  display: flex;
  text-align: ${props => props.align ?? 'center'};
  justify-content: ${props =>
    props.align === 'left' ? 'flex-start' : props.align === 'right' ? 'flex-end' : 'center'};
  gap: 8px;
  margin: auto 0;
  padding: 8px 16px;
  background: var(${ThemeColor.Secondary});
  color: var(${ThemeColor.TextSecondary});
  transition: color 0.2s, background-color 0.2s;

  @media ${breakpoints.sm} {
    text-align: center;
    justify-content: center;
  }
`

const Dropdown = styled.div`
  position: relative;
  color: var(${ThemeColor.Text});
  background: var(${ThemeColor.Tertiary});
  transition: color 0.2s, background-color 0.2s;

  @media ${breakpoints.sm} {
    text-align: center;
  }

  &:hover,
  &:focus-within {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
`

const SelectedOption = styled.div`
  padding: 8px 16px;
`

export const DropdownMenu = styled.div`
  position: absolute;
  z-index: 30000;
  min-width: fit-content;
  width: 100%;
  display: none;
  padding: 0 0 8px 0;
  background: var(${ThemeColor.Tertiary});
  border-radius: 0 0 10px 10px;
  transition: box-shadow 0.2s, background-color 0.2s;
  outline: none;

  ${Dropdown}:hover &, ${Dropdown}:focus-within &:not(:focus-within) {
    display: block;
    transition: background-color 0.2s;
  }
`

const OptionList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
`

const DropdownOption = styled.li`
  padding: 4px 16px;
  cursor: pointer;
  transition: color 0.2s, background-color 0.2s;

  &:hover {
    color: var(${ThemeColor.Accent});
    background: var(${ThemeColor.Secondary});
  }
`

const ErrorText = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 5px;
  text-align: center;
  color: var(${ThemeColor.Error});
  transition: color 0.2s;
`

const WarningText = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 5px;
  text-align: center;
  color: var(${ThemeColor.Warning});
  transition: color 0.2s;
`

const Container = styled.div<{ isError: boolean; isWarning: boolean }>`
  position: relative;
  display: flex;
  background: var(${ThemeColor.Secondary});
  border-radius: 10px;

  outline: ${props =>
    props.isError
      ? `var(${ThemeColor.Error}) solid 2px`
      : props.isWarning
      ? `var(${ThemeColor.Warning}) solid 2px`
      : `var(${ThemeColor.Accent}) solid 0px`};
  transition: outline-color 0.2s, background-color 0.2s;

  @media ${breakpoints.sm} {
    flex-direction: column;
  }

  &:focus-within {
    outline: ${props =>
      props.isError
        ? `var(${ThemeColor.Error}) solid 2px`
        : props.isWarning
        ? `var(${ThemeColor.Warning}) solid 2px`
        : `var(${ThemeColor.Accent}) solid 2px`};
  }

  & > :not(${Label}) {
    flex-grow: 1;
  }

  & > * {
    white-space: nowrap;
    border-radius: 0;

    :nth-child(1 of :not(${TooltipPopup}):not(${TooltipModal}):not(${ErrorText}):not(${WarningText})) {
      border-radius: 10px 0 0 10px;
    }

    :nth-last-child(1 of :not(${TooltipPopup}):not(${TooltipModal}):not(${ErrorText}):not(${WarningText})) {
      border-radius: 0 10px 10px 0;
    }

    @media ${breakpoints.sm} {
      :nth-child(1 of :not(${TooltipPopup}):not(${TooltipModal}):not(${ErrorText}):not(${WarningText})) {
        border-radius: 10px 10px 0 0;
      }

      :nth-last-child(
          1 of :not(${DropdownMenu}):not(${TooltipPopup}):not(${TooltipModal}):not(${ErrorText}):not(${WarningText})
        ) {
        border-radius: 0 0 10px 10px;
      }
    }
  }
`

const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 4px;
`

const SliderValue = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  user-select: none;
  pointer-events: none;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 2px;
  box-sizing: border-box;
`

const SliderValueInner = styled.p`
  position: absolute;
  color: var(${ThemeColor.Text});
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 9px;
  font-weight: bold;
  margin: 0 18px;
`

const Slider = styled.input.attrs({ type: 'range' })`
  appearance: none;
  background: transparent;
  outline: none;
  width: 100%;

  &::-webkit-slider-runnable-track {
    appearance: none;
    width: 100%;
    height: 4px;
    margin: 16px 0;
    border-radius: 2px;
    background-color: var(${ThemeColor.Tertiary});
    transition: background-color 0.3s;
  }

  &::-webkit-slider-thumb {
    appearance: none;
    transform: translateY(-16px);
    width: 36px;
    height: 36px;
    border-radius: 8px;
    background-color: var(${ThemeColor.TextSecondary});
    transition: background-color 0.3s;
    cursor: pointer;
  }

  &:focus::-webkit-slider-thumb {
    background-color: var(${ThemeColor.Accent});
  }
`

namespace FieldParts {
  type Base = {
    value: string
    tooltip?: string
  }
  export type Label = Base & { type: 'label'; textAlign?: 'left' | 'right' | 'center' }

  export type Number = Base & {
    type: 'text'
    forceWidth?: boolean
    textAlign?: 'left' | 'right' | 'center'
    onChange?: ChangeEventHandler<HTMLInputElement>
  }
  export type Slider = Base & {
    type: 'slider'
    min: string
    max: string
    step: string
    onChange?: ChangeEventHandler<HTMLInputElement>
  }
  export type Dropdown = Base & {
    type: 'dropdown'
    options: (ChoiceEntry | string)[]
    onChange?: (value: string) => void
  }
}

// The field is composed of multiple parts.
// This type represents part of a field. Can be a label, number or slider.
type FieldPart = FieldParts.Label | FieldParts.Number | FieldParts.Slider | FieldParts.Dropdown

type BaseFieldProps = {
  error?: string
  warning?: string
  disabled?: boolean
  format?: RegExp | ((value: string) => boolean)
  className?: string
}

/**
 * A modular component that can be used as an input field.
 *
 * @param props.error The error message to display.
 * @param props.warning The warning message to display.
 * @param props.disabled Whether the field is read-only.
 * @param props.onChange The callback to call when the value changes.
 * @param props.format The format of the value. Can be a regular expression or a boolean function over the input value.
 * @param props.className The class name to apply to the component.
 * @param parts The parts of the field.
 */
export default function Field(props: BaseFieldProps & { parts: [FieldPart, ...FieldPart[]]; 'data-testid'?: string }) {
  return (
    <Container
      className={props.className}
      isError={!!props.error}
      isWarning={!!props.warning}
      data-testid={props['data-testid']}
    >
      {
        // Render every part of the field.
        props.parts.reduce(
          (acc, part, i) => {
            let partComponent
            switch (part.type) {
              case 'label':
                partComponent = (
                  <Label align={part.textAlign} key={i}>
                    {part.textAlign !== 'left' && part.value}
                    {part.tooltip && <Tooltip>{props.parts[acc[2]].tooltip}</Tooltip>}
                    {part.textAlign === 'left' && part.value}
                  </Label>
                )
                break
              case 'text':
                partComponent = (
                  <TextBox
                    type='text'
                    align={part.textAlign}
                    disabled={props.disabled}
                    value={part.value}
                    forceWidth={part.forceWidth}
                    onChange={
                      part.onChange &&
                      (ev => {
                        // If a format is specified, check that the value matches it.
                        // Otherwise, check that the value is a valid decimal number.
                        if (
                          (props.format &&
                            ((props.format instanceof RegExp && !props.format.test(ev.target.value)) ||
                              (!(props.format instanceof RegExp) && !props.format(ev.target.value)))) ||
                          (!props.format && !/^[+-]?([0-9]*[.])?[0-9]*$/.test(ev.target.value))
                        ) {
                          ev.preventDefault()
                          return
                        }

                        if (part.onChange) part.onChange(ev)
                      })
                    }
                    data-testid={`${props['data-testid']}-field-${acc[1]}`}
                    key={i}
                  />
                )
                break
              case 'slider': {
                const ratio = (+part.value - +part.min) / (+part.max - +part.min)
                partComponent = (
                  <SliderWrapper key={i}>
                    <Slider
                      min={parseFloat(part.min)}
                      max={parseFloat(part.max)}
                      step={parseFloat(part.step)}
                      value={part.value}
                      onChange={ev => part.onChange?.(ev)}
                      data-testid={`${props['data-testid']}-field-${acc[1]}`}
                      key={i}
                    />
                    <SliderValue>
                      <SliderValueInner
                        style={{
                          left: `calc(${ratio * 100}% - ${ratio * 40}px`,
                        }}
                      >
                        {truncate(parseFloat(part.value), 2)}
                      </SliderValueInner>
                    </SliderValue>
                  </SliderWrapper>
                )
                break
              }

              case 'dropdown': {
                const selected =
                  part.options.find(v => (typeof v === 'string' ? v === part.value : v.value === part.value)) ??
                  part.options[0]

                partComponent = (
                  <Dropdown key={i}>
                    <SelectedOption tabIndex={0}>
                      {typeof selected === 'string' ? selected : selected.name}
                    </SelectedOption>
                    <DropdownMenu>
                      <OptionList>
                        {part.options.map((option, i) => (
                          <DropdownOption
                            key={i}
                            tabIndex={0}
                            onClick={() => {
                              part.onChange?.(typeof option === 'string' ? option : option.value)
                            }}
                          >
                            {typeof option === 'string' ? option : option.name}
                          </DropdownOption>
                        ))}
                      </OptionList>
                    </DropdownMenu>
                  </Dropdown>
                )
                break
              }
            }

            return [
              [...acc[0], partComponent],
              part.type === 'label' ? acc[1] : acc[1] + 1,
              part.tooltip ? acc[2] : acc[2] + 1,
            ] as [ReactNode[], number, number]
          },
          [[] as ReactNode[], 0, 0] as [ReactNode[], number, number]
        )[0]
      }

      {props.error && (
        <ErrorText role='alert' data-testid='field-error'>
          {props.error}
        </ErrorText>
      )}
      {!props.error && props.warning && (
        <WarningText role='alert' data-testid='field-warning'>
          {props.warning}
        </WarningText>
      )}
    </Container>
  )
}

/**
 * A field with a single input.
 *
 * @param label The label of the field.
 * @param units The units shown on the right-hand side of the field.
 * @param tooltip The tooltip shown when hovering over the (?)-symbol the field.
 * @param value The value of the field.
 * @param onChange The callback to call when the value changes.
 */
export function SingleField({
  type,
  label,
  units,
  tooltip,
  value,
  options,
  onChange,
  ...props
}: BaseFieldProps & {
  label: string
  units?: string
  tooltip?: string
  'data-testid'?: string
  value: string
} & (
    | {
        type: 'text'
        onChange?: React.ChangeEventHandler<HTMLInputElement>
        options?: never
      }
    | FieldParts.Dropdown
  )) {
  return (
    <Field
      parts={[
        { type: 'label', textAlign: 'right', tooltip, value: label },
        type === 'text' ? { type, value, onChange } : { type, value, onChange, options },
        ...(units ? [{ type: 'label', textAlign: 'left', value: units } as FieldPart] : []),
      ]}
      {...props}
    />
  )
}

/**
 * A field with two inputs. Used for ranges.
 * The inputs are separated by a separator.
 *
 * @param label The label of the field.
 * @param units The units shown on the right-hand side of the field.
 * @param separator The separator between the two inputs. Defaults to '-'.
 * @param tooltip The tooltip shown when hovering over the (?)-symbol the field.
 * @param value The value of the field. A tuple of two strings.
 */
export const RangeField = ({
  label,
  units,
  separator,
  tooltip,
  value,
  onChange,
  ...props
}: BaseFieldProps & {
  label: string
  units?: string
  separator?: string
  tooltip?: string
  value: [string, string]
  onChange: (ev: ChangeEvent<HTMLInputElement>, i: number) => void
}) => (
  <Field
    parts={[
      { type: 'label', textAlign: 'right', tooltip: tooltip, value: label },
      { type: 'text', textAlign: 'right', value: value[0], onChange: ev => onChange?.(ev, 0) },
      { type: 'label', textAlign: 'center', value: separator ?? '-' },
      { type: 'text', value: value[1], onChange: ev => onChange?.(ev, 1) },
      ...(units ? [{ type: 'label', textAlign: 'left', value: units } as FieldPart] : []),
    ]}
    {...props}
  />
)

export const InnerSliderField = styled(Field)``

export const InnerNumberField = styled(Field)``

/**
 * A field with a single input that can be turned into a slider.
 * The slider is shown when the slider button is pressed.
 * Can be turned back into a normal field by pressing the button again.
 *
 * Lower and upper bounds of slider are set automagically when switched from single input field to slider.
 *
 * @param label The label of the field.
 * @param units The units shown on the right-hand side of the field.
 * @param tooltip The tooltip shown when hovering over the (?)-symbol the field.
 * @param value The value of the field.
 * @param onChange The callback to call when the value changes.
 */
export const SliderField = ({
  label,
  units,
  tooltip,
  constantValue,
  valueType,
  onChange,
  className,
  ...props
}: {
  disabled?: boolean
  label: string
  units?: string
  tooltip?: string
  constantValue: ConstantValue
  valueType: ValueTypes.Unit
  error?: string
  warning?: string
  onChange?: (constantValue: ConstantValue) => void
  'data-testid'?: string
  className?: string
}) => (
  <SliderContainer data-testid={props['data-testid']} className={className}>
    {constantValue.type === 'slider' && (
      // Render as a slider field.
      <InnerSliderField
        data-testid='slider'
        parts={[
          { type: 'label', textAlign: 'right', tooltip: tooltip, value: label },
          {
            type: 'text',
            textAlign: 'right',
            value: constantValue.lowerBound,
            forceWidth: true,
            onChange: ev =>
              onChange?.({
                type: 'slider',
                value: constantValue.value,
                lowerBound: ev.target.value,
                upperBound: constantValue.upperBound,
              }),
          },
          {
            type: 'slider',
            value: constantValue.value,
            min: constantValue.lowerBound,
            max: constantValue.upperBound,
            // 20 steps in slider.
            step: ((parseFloat(constantValue.upperBound) - parseFloat(constantValue.lowerBound)) / 20).toString(),
            onChange: ev =>
              onChange?.({
                type: 'slider',
                value: ev.target.value,
                lowerBound: constantValue.lowerBound,
                upperBound: constantValue.upperBound,
              }),
          },
          {
            type: 'text',
            value: constantValue.upperBound,
            forceWidth: true,
            onChange: ev =>
              onChange?.({
                type: 'slider',
                value: constantValue.value,
                lowerBound: constantValue.lowerBound,
                upperBound: ev.target.value,
              }),
          },
          ...(units ? [{ type: 'label', textAlign: 'left', value: units } as FieldPart] : []),
        ]}
        {...props}
      />
    )}
    {constantValue.type === 'input' && (
      // Render as a single input field.
      <InnerNumberField
        data-testid='single'
        parts={[
          { type: 'label', textAlign: 'right', tooltip, value: label },
          {
            type: 'text',
            value: constantValue.value,
            onChange: ev => onChange?.({ type: 'input', value: ev.target.value }),
          },
          ...(units ? [{ type: 'label', textAlign: 'left', value: units } as FieldPart] : []),
        ]}
        {...props}
      />
    )}
    <ClickIcon
      data-testid={`${props['data-testid']}-toggle`}
      icon={faSliders}
      onClick={() => {
        // Toggle between slider and single input field.

        if (constantValue.type == 'slider') {
          // Slider -> single input field.
          onChange &&
            onChange({
              type: 'input',
              value: constantValue.value,
            })

          return
        }

        // Single input field -> slider.
        // Automatically compute lower and upper bounds.
        const [defaultLowerBound, defaultUpperBound] = computeDefaultBounds(
          parseFloat(constantValue.value),
          valueType.physicalRange
        )

        onChange &&
          onChange({
            type: 'slider',
            value: constantValue.value,
            lowerBound: defaultLowerBound.toString(),
            upperBound: defaultUpperBound.toString(),
          })
      }}
    />
  </SliderContainer>
)
