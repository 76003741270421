import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { animated, useSpring } from '@react-spring/web'
import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import useMeasure from 'react-use-measure'
import styled from 'styled-components'
import { ThemeColor } from 'themes'

const CardLink = styled(Link)`
  text-decoration: none;
`

const CardBox = styled.div<{ nesting: number }>`
  display: flex;
  flex-direction: column;
  background: var(${props => (props.nesting % 2 === 0 ? ThemeColor.Secondary : ThemeColor.Primary)});
  padding: 5px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.2s;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 2rem;
  color: var(${ThemeColor.Text});
  transition: color 0.2s;
`

const Description = styled.div`
  width: 100%;
  text-align: justify;
  color: var(${ThemeColor.TextSecondary});
  padding-bottom: 10px;
  transition: color 0.2s;
`

const SubCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 15px;
`

type CardProps = {
  ident: string
  name: string
  docstring?: string
  nesting?: number
  subcards?: CardProps[]
  tabIndex?: number
}

/**
 * Component for displaying formula entries.
 * Currently used to show a formula in the search results, or a group of formulas on the home page inside the label groups.
 *
 * @param ident The identifier of the formula.
 * @param name The name of the formula.
 * @param docstring The description of the formula.
 * @param nesting The nesting level of the card. Used to alternate the background color.
 * @param subcards The subcards of the card. Used to display the formulas inside a label group.
 */
export default function Card({ ident, name, docstring, nesting, subcards, tabIndex }: CardProps) {
  const [expanded, setExpanded] = useState(false)

  // To measure the height of the subcards.
  const cardRef = useRef<HTMLDivElement>(null)
  const [measureRef, { height: measuredHeight }] = useMeasure()

  // To open the card with an animation.
  const { rotate, height } = useSpring({
    rotate: expanded ? 90 : 0,
    height: expanded ? measuredHeight : 0,
  })

  const children = (
    <CardBox
      nesting={nesting ?? 0}
      ref={cardRef}
      onClick={
        subcards &&
        (() => {
          setExpanded(!expanded)
        })
      }
      onKeyDown={ev => {
        if (ev.key === 'Enter' && subcards) {
          setExpanded(!expanded)
        }
      }}
      tabIndex={subcards && (tabIndex ?? 0)}
      aria-label={`${subcards ? 'keyword' : 'formula'}: ${name}`}
    >
      <Title>
        {subcards && (
          <animated.div style={{ rotate }}>
            <FontAwesomeIcon icon={faChevronRight} size='xs' />
          </animated.div>
        )}
        {name}
      </Title>
      {docstring && <Description>{docstring}</Description>}

      {subcards && (
        <animated.div style={{ overflow: 'hidden', height }}>
          <SubCards ref={measureRef}>
            {subcards.map(cardProps => (
              <Card key={cardProps.ident} {...cardProps} nesting={(nesting ?? 0) + 1} tabIndex={expanded ? 0 : -1} />
            ))}
          </SubCards>
        </animated.div>
      )}
    </CardBox>
  )

  return subcards ? (
    children
  ) : (
    <CardLink to={`/formula/${ident}`} tabIndex={tabIndex ?? 0}>
      {children}
    </CardLink>
  )
}
