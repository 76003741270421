import { faGitlab } from '@fortawesome/free-brands-svg-icons'
import { faGlobe, faMoon, faSun } from '@fortawesome/free-solid-svg-icons'
import logoBlack from 'assets/img/logoBlack.svg'
import logoWhite from 'assets/img/logoWhite.svg'
import config from 'config'
import i18n from 'i18n'
import { useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ThemeColor, ThemeContext } from 'themes'
import ClickIcon from './ClickIcon'
import PickDialog, { PickDialogRef } from './PickDialog'
import SearchBar from './SearchBar'

const NavBox = styled.div`
  width: 100vw;
  height: 80px;
  background: var(${ThemeColor.Secondary});
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  display: grid;
  grid-template-columns: 1fr min(1100px, calc(100% - 60px)) 1fr;
  padding: 10px 0;
  transition: background-color 0.2s;

  & > * {
    grid-column: 2;
  }
`

const NavContent = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: end;
`

const Logo = styled.img`
  height: 49px;
  align-self: end;
`

const Search = styled(SearchBar)`
  height: 80%;
  flex-grow: 1;
  border-radius: 10px;
  background: var(${ThemeColor.Primary});
  font-size: 1.2rem;
  padding: 0 14px 0;
  transition: background-color 0.2s;

  & > input {
    appearance: none;
    height: 100%;
    width: 100%;
    background: transparent;
    color: var(${ThemeColor.Text});
    border: none;
    transition: color 0.2s;

    &::placeholder {
      color: var(${ThemeColor.TextSecondary});
      transition: color 0.2s;
    }

    &:focus {
      outline: none;
    }
  }
`

const NavIcon = styled(ClickIcon)`
  font-size: 2.2rem;
`

/**
 * The navigation bar.
 *
 * @param props.home Whether the navbar is on the home page.
 * @param props.searchText The text to put in the search bar.
 * @param props.changeTheme The function to change the theme.
 */
export default function Navbar(props: { home: boolean; searchText?: string; changeTheme: () => void }) {
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()

  // The languages to show in the language picker.
  const dialogRef = useRef<PickDialogRef>(null)
  const languageItems = Object.fromEntries(
    Object.keys(i18n.services.resourceStore.data).map(language => [
      language,
      new Intl.DisplayNames([language], { type: 'language' }).of(language) ?? language,
    ])
  )

  return (
    <NavBox>
      <PickDialog ref={dialogRef} title={t('change_language')} options={languageItems} onPick={i18n.changeLanguage} />
      <NavContent>
        {!props.home && (
          <>
            <Link to='/'>
              <Logo src={theme === 'light' ? logoBlack : logoWhite} alt={t('logo')} />
            </Link>
            <Search defaultValue={props.searchText} />
          </>
        )}
        <NavIcon icon={faGitlab} to={config.repositoryBaseUrl} target='_blank' rel='noopener' ariaLabel='Source code' />
        <NavIcon
          icon={theme === 'light' ? faSun : faMoon}
          onClick={props.changeTheme}
          onKeyDown={e => e.key === 'Enter' && props.changeTheme()}
          tabIndex={0}
          ariaLabel='Change colour theme'
        />
        <NavIcon
          icon={faGlobe}
          onClick={() => dialogRef.current?.showModal()}
          onKeyDown={e => e.key === 'Enter' && dialogRef.current?.showModal()}
          tabIndex={0}
          ariaLabel='Change language'
        />
      </NavContent>
    </NavBox>
  )
}
