import useStickyState from 'hooks/useStickyState'
import { LoaderFunction, Outlet, useLoaderData } from 'react-router-dom'
import styled from 'styled-components'
import themes, { ThemeContext } from 'themes'
import { ThemeWrapper } from 'views'
import { FormulaHeader, loader as formulaLoader } from 'views/Formula'

const Wrapper = styled(ThemeWrapper)`
  padding: 25px 0;
  min-height: 100vh;
`

const Title = styled(FormulaHeader)`
  margin: 0;
  padding: 0;
  padding-bottom: 20px;
`

export const loader = (async args => {
  const formulaData = formulaLoader(args)

  const url = new URL(args.request.url)
  const colorTheme = url.searchParams.get('theme')

  return { ...(await formulaData), colorTheme }
}) satisfies LoaderFunction

export default function Embed() {
  const { environment, formula, colorTheme } = useLoaderData() as LoaderData<typeof loader>
  const [storedTheme] = useStickyState<keyof typeof themes>(
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
    'colorTheme'
  )

  const theme = colorTheme && Object.hasOwn(themes, colorTheme) ? (colorTheme as keyof typeof themes) : storedTheme

  return (
    <ThemeContext.Provider value={theme}>
      <Wrapper colorTheme={theme} data-testid={`root-${colorTheme}`}>
        <Title>{formula.name}</Title>
        <Outlet context={{ environment, formula }} />
      </Wrapper>
    </ThemeContext.Provider>
  )
}
