import en from 'assets/locales/en/translation.json'
import sl from 'assets/locales/sl/translation.json'
import i18next, { use } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

// The default namespace for translations.
// The filenames of the translation files should be the same as the namespace.
export const defaultNS = 'translation'

// The resources for the different languages.
// When adding a new language, add it here and create a new translation file.
export const resources = {
  en: {
    [defaultNS]: en,
  },
  sl: {
    [defaultNS]: sl,
  },
} as const

// Initialize i18next.
use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: 'en', // Use English if the user's language is not supported, or if a translation is missing.
    load: 'languageOnly',
    interpolation: {
      escapeValue: false,
    },
    defaultNS,
    resources,
  })

export default i18next
