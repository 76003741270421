export default {
  // The base URL of the PyBox back-end API.
  apiUrl: process.env.REACT_APP_API_URL ?? 'https://pybox.citg.tudelft.nl/api/v1',
  // The URL to the package registry of the PyBox Toolkit.
  // This is used to fetch the latest version of the PyBox Toolkit.
  registryUrl: 'https://pybox.citg.tudelft.nl/index/simple/pybox-toolkit',
  // The base URL to the files in the contributors' repository.
  // This is used to link to the source code of the PyBox Toolkit.
  repositoryBaseUrl: `https://gitlab.tudelft.nl/pybox/contributors/-/blob/${
    process.env.REACT_APP_BRANCH_NAME ?? 'main'
  }/`,
  projectUrl: 'https://gitlab.tudelft.nl/pybox/',
  pyodideOptions: {
    // The base URL to the CDN hosting Pyodide.
    // See https://pyodide.org/en/stable/usage/quickstart.html
    indexURL: 'https://cdn.jsdelivr.net/pyodide/v0.23.2/pyc/',
    // The following stdlib modules are unvendored by default, in order to reduce initial download size of Python distribution. You can load all unvendored stdlib modules when initializing Pyodide with, loadPyodide({ fullStdLib : true }). However this has a significant impact on the download size. Instead, it is better to load individual modules as needed using pyodide.loadPackage() or micropip.install().
    // * distutils
    // * ssl
    // * lzma
    // * sqlite3
    // See https://pyodide.org/en/stable/usage/wasm-constraints.html
    fullStdLib: false,
  },
  // Whether the application is in production mode.
  // In production mode, the application will not load development-only versions of dependencies.
  // At the moment of writing, this only affects the PyBox Toolkit version.
  production: false,
}
