import { parseNumber } from 'util/numberUtils'
import ChoiceEntry from './ChoiceEntry'

export namespace ValueTypes {
  type Base = { description: string; isDefaultOutput: boolean; default?: string | null }
  export type Choice = Base & { argumentType: 'CHOICE'; choices: ChoiceEntry[] }
  export type Unit = Base & {
    argumentType: 'UNIT'
    typename: string
    physicalRange: [number, number]
    tentativeRange?: [number, number]
    units: string
  }
}

/**
 * A value type of a parameter.
 */
type ValueType = ValueTypes.Choice | ValueTypes.Unit

namespace ValueType {
  /**
   * Checks if the given JSON object is a valid ValueType.
   * @param json The JSON object to check.
   * @throws SyntaxError if the given JSON object is not a valid ValueType.
   */
  export function isValueType(json: unknown): asserts json is ToJson<ValueType> {
    if (typeof json !== 'object' || json === null) throw SyntaxError('bad ValueType format: not an object')

    if (!('argumentType' in json)) throw SyntaxError('bad ValueType format: missing property argumentType')

    if (typeof json.argumentType !== 'string')
      throw SyntaxError('bad ValueType format: property argumentType is not a string')

    if (!('description' in json)) throw SyntaxError('bad ValueType format: missing property description')

    if (typeof json.description !== 'string')
      throw SyntaxError('bad ValueType format: property description is not a string')

    if (!('isDefaultOutput' in json)) throw SyntaxError('bad ValueType format: missing default ouptut property')

    if (typeof json.isDefaultOutput !== 'boolean')
      throw SyntaxError('bad ValueType format: default ouptut property is not a boolean')

    if ('default' in json && typeof json.default !== 'string' && json.default !== null)
      throw SyntaxError('bad ValueType format: default property is not a string')

    switch (json.argumentType) {
      case 'CHOICE':
        if (!('choices' in json)) throw SyntaxError('bad ValueType format: missing property choices')

        if (!Array.isArray(json.choices)) throw SyntaxError('bad ValueType format: property choices is not an array')

        json.choices.forEach((choice, index) => {
          try {
            ChoiceEntry.isChoiceEntry(choice)
          } catch (error) {
            throw SyntaxError(`bad ValueType format: property choices[${index}] is not a ChoiceEntry`)
          }
        })
        break

      case 'UNIT':
        if (!('typename' in json)) throw SyntaxError('bad ValueType format: missing property typename')

        if (typeof json.typename !== 'string')
          throw SyntaxError('bad ValueType format: property typename is not a string')

        if (!('physicalRange' in json)) throw SyntaxError('bad ValueType format: missing property physicalRange')

        if (!Array.isArray(json.physicalRange))
          throw SyntaxError('bad ValueType format: property physicalRange is not an array')

        if (json.physicalRange.length !== 2)
          throw SyntaxError('bad ValueType format: property physicalRange is not an array of length 2')

        if (
          typeof json.physicalRange[0] !== 'number' &&
          json.physicalRange[0] !== 'Infinity' &&
          json.physicalRange[0] !== '-Infinity'
        )
          throw SyntaxError('bad ValueType format: property physicalRange[0] is not a number')

        if (
          typeof json.physicalRange[1] !== 'number' &&
          json.physicalRange[1] !== 'Infinity' &&
          json.physicalRange[1] !== '-Infinity'
        )
          throw SyntaxError('bad ValueType format: property physicalRange[1] is not a number')

        if ('tentativeRange' in json && json.tentativeRange !== null) {
          if (!Array.isArray(json.tentativeRange))
            throw SyntaxError('bad ValueType format: property tentativeRange is not an array')

          if (json.tentativeRange.length !== 2)
            throw SyntaxError('bad ValueType format: property tentativeRange is not an array of length 2')

          if (
            typeof json.tentativeRange[0] !== 'number' &&
            json.tentativeRange[0] !== 'Infinity' &&
            json.tentativeRange[0] !== '-Infinity'
          )
            throw SyntaxError('bad ValueType format: property tentativeRange[0] is not a number')

          if (
            typeof json.tentativeRange[1] !== 'number' &&
            json.tentativeRange[1] !== 'Infinity' &&
            json.tentativeRange[1] !== '-Infinity'
          )
            throw SyntaxError('bad ValueType format: property tentativeRange[1] is not a number')
        }

        if (!('units' in json)) throw SyntaxError('bad ValueType format: missing property units')

        if (typeof json.units !== 'string') throw SyntaxError('bad ValueType format: property units is not a string')

        break

      default:
        throw SyntaxError('bad ValueType format: argumentType is not a valid value')
    }
  }

  /**
   * Creates a JSON object from the given ValueType.
   * @param json The JSON object to create the ValueType from.
   * @throws SyntaxError if the given JSON object is not a valid ValueType.
   * @returns The created ValueType.
   */
  export function fromJson(json: unknown): ValueType {
    isValueType(json)

    switch (json.argumentType) {
      case 'CHOICE':
        return {
          argumentType: 'CHOICE',
          description: json.description,
          isDefaultOutput: json.isDefaultOutput,
          default: json.default,
          choices: json.choices,
        }
      case 'UNIT':
        return {
          argumentType: 'UNIT',
          description: json.description,
          isDefaultOutput: json.isDefaultOutput,
          default: json.default,
          typename: json.typename,
          physicalRange: [parseNumber(json.physicalRange[0]), parseNumber(json.physicalRange[1])],
          tentativeRange: json.tentativeRange && [
            parseNumber(json.tentativeRange[0]),
            parseNumber(json.tentativeRange[1]),
          ],
          units: json.units,
        }
    }
  }
}

export default ValueType
