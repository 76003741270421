import { faChevronLeft, faPlusCircle, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import breakpoints from 'breakpoints'
import Button from 'components/Button'
import Checkbox from 'components/Checkbox'
import ClickIcon from 'components/ClickIcon'
import { InnerNumberField, InnerSliderField, RangeField, SingleField, SliderField } from 'components/Field'
import { ExecutionEnvironmentId } from 'domain/entities/ExecutionEnvironment'
import FormulaInfo from 'domain/entities/Formula'
import GraphSettings, { AxisSettings, Line } from 'domain/entities/GraphSettings'
import pyodideService from 'domain/services/PyodideServiceProxy'
import { GraphLine } from 'domain/services/WorkerPyodideService'
import useStickyState from 'hooks/useStickyState'
import i18n from 'i18n'
import { cloneDeep } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Plot from 'react-plotly.js'
import { NavigateFunction, useLocation, useNavigate, useOutletContext } from 'react-router-dom'
import styled from 'styled-components'
import themes, { ThemeColor, ThemeContext } from 'themes'
import { decodeBase64, encodeBase64 } from 'util/encoding'
import downloadFile from 'util/fileUtils'
import { tooltip } from 'util/tooltipUtils'

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr min(1100px, calc(100% - 60px)) 1fr;
  gap: 20px;
  justify-items: center;

  & > * {
    grid-column: 2;
  }
`

const BackButton = styled(ClickIcon)`
  width: fit-content;
  font-size: 1.4rem;
`

const StyledPlot = styled(Plot)`
  grid-column: 1 / -1;
  width: calc(100% - 60px);
  max-width: 1100px;
`

const OutputError = styled.div`
  text-align: center;
  color: var(${ThemeColor.Error});
  transition: color 0.2s;
  font-size: 1.1rem;
`

const GraphDataControlsTitle = styled.h3`
  text-align: center;
  color: var(${ThemeColor.Text});
  transition: color 0.2s;
`

const GraphDataControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  width: 100%;
  flex-wrap: wrap;

  & > * {
    flex-grow: 1;
  }
`

const ConstantsTitle = styled.h3`
  text-align: center;
  color: var(${ThemeColor.Text});
  transition: color 0.2s;
`

const ConstantsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 10px;
  width: 100%;
  flex-grow: 1;
  border: 2px solid var(${ThemeColor.Tertiary});
  border-radius: 20px;
  transition: border-color 0.2s;
`

const ConstantsHeader = styled.div`
  display: flex;
  align-items: center;
`

const Constants = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto minmax(0, 1fr) auto auto);
  gap: 30px;

  @media ${breakpoints.md} {
    grid-template-columns: auto minmax(0, 1fr) auto auto;
  }
`

const DeleteConstantsButton = styled.div`
  background-color: var(${ThemeColor.Tertiary});
  height: calc(100% + 10px);
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  top: -5px;
  right: -10px;
  border-radius: 0 17px 0 10px;
  transition: background-color 0.2s;
`

const DeleteIcon = styled(FontAwesomeIcon)`
  color: var(${ThemeColor.Primary});
  font-size: 2rem;
  transition: color 0.2s;

  ${DeleteConstantsButton}:hover > & {
    color: var(${ThemeColor.Error});
  }
`

const ConstantsNameField = styled(SingleField)`
  flex-grow: 1;
`

const ConstantsSeparator = styled.hr`
  width: 100%;
  margin: 0;
  border: 1px solid var(${ThemeColor.Tertiary});
  color: var(${ThemeColor.Tertiary});
  transition: color 0.2s, background-color 0.2s, border-color 0.2s;
`

const ConstantSliderField = styled(SliderField)`
  display: grid !important;
  grid-template-columns: auto minmax(0, 1fr) auto auto;
  grid-column: span 4;

  @supports (grid-template-columns: subgrid) {
    grid-template-columns: subgrid;
  }

  & ${InnerSliderField} {
    grid-column: span 3;
    width: 100%;
  }

  & ${InnerNumberField} {
    display: grid !important;
    grid-template-columns: auto minmax(0, 1fr) auto;
    grid-column: span 3;
    gap: 0;
    width: 100%;

    @supports (grid-template-columns: subgrid) {
      grid-template-columns: subgrid;
    }

    /* hack because using TextBox glitches sometimes */
    & :nth-child(2) {
      grid-column-start: 2;
    }

    & :last-child {
      grid-column-end: -1;
    }

    @media ${breakpoints.sm} {
      display: flex !important;
    }
  }

  @media ${breakpoints.sm} {
    display: flex !important;
  }
`

const DownloadLineButton = styled(Button)`
  width: 100%;
  grid-column: span 4;
`

const AddConstantsButton = styled(ClickIcon)`
  font-size: 2rem;
  width: min-content;
`

/**
 * Data returned by PyodideService, plus an error message if any.
 */
type GeneratedData = {
  lines: GraphLine[]
  error?: string
}

// TODO: document
export function linesToCsv(lines: GraphLine[], input: string, output: string) {
  if (lines.length === 0) {
    return ''
  }

  let csv = ''
  lines.forEach(line => {
    const sanitizedName = line.name.replace('"', '""')
    csv += `"${sanitizedName}_${input}","${sanitizedName}_${output}",`
  })
  csv += '\n'
  for (let i = 0; i < lines[0].x.length; i++) {
    lines.forEach(line => {
      csv += `${line.x[i]},${line.y[i]},`
    })
    csv += '\n'
  }

  return csv
}

// TODO: document
function downloadCsvFile(csv: string, name: string) {
  downloadFile(`${name}.csv`, 'text/csv', csv)
}

/**
 * Computes the graph data using the PyodideService.
 *
 * @param formulaEnvironment The formula environment to use.
 * @param input The input variable to graph.
 * @param output The output variable to graph.
 * @param inputRange The input range.
 * @param lines The lines to graph, each with their own sets of constants.
 * @param iterations The number of iterations to compute.
 * @returns The graph data.
 */
export async function generateData(
  formulaEnvironment: Promise<ExecutionEnvironmentId>,
  input: string,
  output: string,
  inputRange: [number, number],
  lines: Line[],
  iterations: number
): Promise<GeneratedData> {
  try {
    const result = await Promise.all(
      lines.map(async line =>
        pyodideService.computeGraphPoints(await formulaEnvironment, input, output, inputRange, line, iterations)
      )
    )

    return { lines: result }
  } catch (err) {
    /* istanbul ignore next */
    if (process.env.NODE_ENV === 'development') {
      console.error(err)
    }

    return { lines: [], error: i18n.t('error.graph_unavailable') }
  }
}

/**
 * Loads the graph data from the URL.
 * Used so people can share formulas with the inputs pre-populated.
 *
 * @param search The URL search params.
 * @returns The graph data.
 */
export const loadSearchParams = (search: string) => {
  const params = new URLSearchParams(search).get('params')

  if (params == null) {
    throw new Error('No params found in search')
  }

  const { min, max, iterations, input, output, lines, title, xAxis, yAxis, lineSmoothing } = GraphSettings.fromJson(
    JSON.parse(decodeBase64(params))
  )

  return {
    inputRange: [min, max] as [string, string],
    iterations: iterations,
    input,
    output,
    lines,
    title,
    xAxis,
    yAxis,
    lineSmoothing,
  }
}

/**
 * Updates the search URL without actually reloading the website.
 *
 * @param navigate The navigate function from React Router.
 * @param inputRange The input range.
 * @param iterations The number of iterations to compute.
 * @param input The input variable to graph.
 * @param output The output variable to graph.
 * @param lines The lines to graph, each with their own sets of constants.
 * @param xAxis The x-axis settings.
 * @param yAxis The y-axis settings.
 * @param lineSmoothing Whether to smooth the lines.
 */
export const updateSearchParams = (
  navigate: NavigateFunction,
  inputRange: [string, string],
  iterations: string,
  input: string,
  output: string,
  lines: Line[],
  title?: string,
  xAxis?: AxisSettings,
  yAxis?: AxisSettings,
  lineSmoothing?: boolean
) => {
  const search = new URLSearchParams(location.search)
  const params = {
    min: inputRange[0],
    max: inputRange[1],
    iterations,
    input,
    output,
    lines,
    title,
    xAxis,
    yAxis,
    lineSmoothing,
  }
  search.set('params', encodeBase64(JSON.stringify(params)))

  navigate('?' + search.toString(), { replace: true, preventScrollReset: true })
}

/**
 * Navigates back to the fields page.
 * Used when the user clicks the back button.
 * Loads the previous field settings from the URL.
 * If there are no previous field settings, it goes back to the formula page.
 *
 * @param navigate The navigate function from React Router.
 * @param search The URL search params.
 * @todo Add tests for this function.
 */
export const backToFields = (navigate: NavigateFunction, search: string) => {
  navigate('..')
  const searchParams = new URLSearchParams(search)

  // If there are no previous field settings, go back to the formula page.
  const fieldSettings = searchParams.get('prevFieldParams')
  if (fieldSettings === null) {
    navigate('..')
    return
  }

  // Set the field page params.
  searchParams.delete('prevFieldParams')
  searchParams.set('params', fieldSettings)

  navigate('?' + searchParams.toString(), { replace: true, preventScrollReset: false })
}

/**
 * Graph view.
 *
 * The graphing page contains a graph, some controls, a list of constants, and the
 * same documentation and test cases as presented on the formula page.
 *
 * It is used for visualizing formulas.
 */
export default function Graph() {
  const { environment, formula }: { environment: Promise<ExecutionEnvironmentId>; formula: FormulaInfo } =
    useOutletContext()
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  // Load the search params from the URL.
  let searchParams: ReturnType<typeof loadSearchParams> | undefined
  try {
    searchParams = loadSearchParams(location.search)
  } catch {
    searchParams = undefined
  }

  useEffect(() => {
    // If there are no search params, go back to the formula page.
    if (searchParams === undefined) {
      navigate(`..`)
    }
  }, [location.search])

  // If there are no search params, don't render anything.
  // We will soon be redirected to the formula page. See the useEffect above.
  if (searchParams === undefined) {
    return null
  }

  const { inputRange, iterations, input, output, lines, title, xAxis, yAxis, lineSmoothing } = searchParams
  const [graphHeight, setGraphHeight] = useStickyState('500', 'graph-height')
  const [data, setData] = useState<GeneratedData>({ lines: [] })
  const [fieldHasError, setFieldHasError] = useState(false)

  // We can only graph unit types.
  const inputArgument = formula.arguments[input]
  if (inputArgument.argumentType !== 'UNIT') {
    throw SyntaxError(t('error.graph_only_units'))
  }

  const themeContext = useContext(ThemeContext)

  useEffect(() => {
    // When pyodide is till loading we set an error
    setData({ error: 'Loading pyodide...', ...data })

    // Then we known it has loaded when we receive the environment
    environment.then(() => {
      delete data.error
      setData(data)
    })

    if (fieldHasError) {
      return
    }

    // Generate the data for the graph initially and whenever the search params change.
    generateData(
      environment,
      input,
      output,
      [parseFloat(inputRange[0]), parseFloat(inputRange[1])],
      lines,
      parseInt(iterations)
    ).then(setData)
  }, [location.search])

  // Keep track of whether the field has an error.
  // Just before rendering, we set the fieldHasError state based on this variable.
  // This is a performance optimization. Otherwise, the component is rerendered
  // many times.
  let _fieldHasError = false

  // Note that we don't return yet. We will do that after we set the fieldHasError state.
  const out = (
    <Container>
      <BackButton
        data-testid='back-button'
        icon={faChevronLeft}
        onClick={() => backToFields(navigate, location.search)}
        tabIndex={0}
        ariaLabel='Share'
        role='alert'
      />
      <StyledPlot
        data-testid='graph'
        data={data.lines.map(line => ({
          ...line,
          type: 'scatter',
          mode: 'lines',
          marker: { color: themes[themeContext][ThemeColor.Accent] },
          line: {
            shape: 'spline',
            smoothing: lineSmoothing ? 1.3 : 0,
          },
        }))}
        layout={{
          title: {
            font: {
              color: themes[themeContext][ThemeColor.TextSecondary],
              size: 32,
            },
            text: `<i>${title ?? ''}</i>`,
          },
          plot_bgcolor: themes[themeContext][ThemeColor.Secondary],
          paper_bgcolor: 'transparent',
          margin: { l: 60, r: 0, t: title && title != '' ? 60 : 0, b: 40 },
          autosize: true,
          height: Math.min(Math.max(parseInt(graphHeight) || 300, 300), 1000),
          xaxis: {
            color: themes[themeContext][ThemeColor.TextSecondary],
            title: xAxis?.label ?? input,
            type: xAxis?.type,
          },
          yaxis: {
            color: themes[themeContext][ThemeColor.TextSecondary],
            title: yAxis?.label ?? output,
            type: yAxis?.type,
          },
          legend: { font: { color: themes[themeContext][ThemeColor.TextSecondary] } },
        }}
        useResizeHandler
      />
      {data.error && <OutputError data-testid='error-message'>{data.error.toString()}</OutputError>}
      <GraphDataControlsTitle>{t('graph.controls_title')}</GraphDataControlsTitle>
      <GraphDataControls>
        <RangeField
          data-testid={`range-${input}`}
          label={input}
          units={inputArgument.units}
          tooltip={tooltip(formula.arguments[input])}
          value={inputRange}
          error={(() => {
            // Error handling for the input range field.
            if (inputRange[0] == '' || inputRange[1] == '') {
              _fieldHasError = true
              return t('graph.errors.no_value')
            }

            if (Number.isNaN(parseFloat(inputRange[0])) || Number.isNaN(parseFloat(inputRange[1]))) {
              _fieldHasError = true
              return t('graph.errors.nan')
            }

            if (parseFloat(inputRange[0]) > parseFloat(inputRange[1])) {
              _fieldHasError = true
              return t('graph.errors.bounds')
            }

            if (parseFloat(inputRange[0]) < inputArgument.physicalRange[0]) {
              _fieldHasError = true
              return t('graph.errors.bound_below_physical_min', { min: inputArgument.physicalRange[0] })
            }

            if (parseFloat(inputRange[1]) > inputArgument.physicalRange[1]) {
              _fieldHasError = true
              return t('graph.errors.bound_above_physical_max', { max: inputArgument.physicalRange[1] })
            }
          })()}
          warning={(() => {
            // Warning handling for the input range field.
            if (inputArgument.tentativeRange !== undefined) {
              if (parseFloat(inputRange[0]) < inputArgument.tentativeRange[0]) {
                return t('graph.errors.bound_below_tentative_min', { min: inputArgument.tentativeRange[0] })
              }

              if (parseFloat(inputRange[1]) > inputArgument.tentativeRange[1]) {
                return t('graph.errors.bound_above_tentative_max', { max: inputArgument.tentativeRange[1] })
              }
            }
          })()}
          onChange={(ev, i) => {
            // Update the range of the input variable.
            const range: [string, string] =
              i === 0 ? [ev.target.value, inputRange[1]] : [inputRange[0], ev.target.value]
            updateSearchParams(navigate, range, iterations, input, output, lines, title, xAxis, yAxis)
          }}
        />
        <SingleField
          type='text'
          data-testid='iterations'
          label={t('graph.iterations')}
          tooltip={t('graph.iterations_tooltip')}
          value={iterations.toString()}
          format={/^[0-9]*$/}
          error={(() => {
            // Error handling for the iterations field.
            if (iterations == '') {
              _fieldHasError = true
              return t('graph.errors.no_value')
            }

            if (parseInt(iterations) < 1) {
              _fieldHasError = true
              return t('graph.errors.iterations_too_low')
            }

            if (parseInt(iterations) > 1000) {
              _fieldHasError = true
              return t('graph.errors.iterations_too_high')
            }
          })()}
          onChange={ev => {
            // Update the number of iterations.
            updateSearchParams(navigate, inputRange, ev.target.value, input, output, lines, title, xAxis, yAxis)
          }}
        />
        <SingleField
          type='text'
          data-testid='x-axis-label'
          label={t('graph.x_axis_label')}
          value={xAxis?.label ?? input}
          format={() => true}
          onChange={ev => {
            // Update the label of the x-axis.
            const newLabel = ev.target.value
            const newXAxis = cloneDeep(xAxis) ?? { type: 'linear', label: input }
            newXAxis.label = newLabel
            updateSearchParams(navigate, inputRange, iterations, input, output, lines, title, newXAxis, yAxis)
          }}
        />
        <Checkbox
          id='x-axis-log'
          label={t('graph.x_axis_log')}
          checked={xAxis?.type == 'log'}
          onChange={checked => {
            // Update the type of the x-axis (log or linear).
            const newXAxis = cloneDeep(xAxis) ?? { type: 'linear', label: input }
            newXAxis.type = checked ? 'log' : 'linear'
            updateSearchParams(navigate, inputRange, iterations, input, output, lines, title, newXAxis, yAxis)
          }}
        />
        <SingleField
          type='text'
          data-testid='y-axis-label'
          label={t('graph.y_axis_label')}
          value={yAxis?.label ?? output}
          format={() => true}
          onChange={ev => {
            // Update the label of the y-axis.
            const newLabel = ev.target.value
            const newYAxis = cloneDeep(yAxis) ?? { type: 'linear', label: output }
            newYAxis.label = newLabel
            updateSearchParams(navigate, inputRange, iterations, input, output, lines, title, xAxis, newYAxis)
          }}
        />
        <Checkbox
          id='y-axis-log'
          label={t('graph.y_axis_log')}
          checked={yAxis?.type == 'log'}
          onChange={checked => {
            // Update the type of the y-axis (log or linear).
            const newYAxis = cloneDeep(yAxis) ?? { type: 'linear', label: output }
            newYAxis.type = checked ? 'log' : 'linear'
            updateSearchParams(navigate, inputRange, iterations, input, output, lines, title, xAxis, newYAxis)
          }}
        />
        <SingleField
          type='text'
          data-testid='graph-title'
          label={t('graph.title')}
          value={title ?? ''}
          format={() => true}
          onChange={ev => {
            // Update the title of the graph.
            const newTitle = ev.target.value
            updateSearchParams(
              navigate,
              inputRange,
              iterations,
              input,
              output,
              lines,
              newTitle,
              xAxis,
              yAxis,
              lineSmoothing
            )
          }}
        />
        <Checkbox
          id='line-smoothing'
          label={t('graph.line_smoothing')}
          checked={lineSmoothing ?? false}
          onChange={checked => {
            // Enable or disable the line smoothing.
            const newLineSmoothing = checked
            updateSearchParams(
              navigate,
              inputRange,
              iterations,
              input,
              output,
              lines,
              title,
              xAxis,
              yAxis,
              newLineSmoothing
            )
          }}
        />
        <SingleField
          type='text'
          data-testid='graph-height'
          label={t('graph.height')}
          value={graphHeight}
          format={/^[0-9]*$/}
          error={(() => {
            // Error handling for the graph height field.
            if (graphHeight == '') {
              _fieldHasError = true
              return t('graph.errors.no_value')
            }

            if (parseInt(graphHeight) < 300) {
              _fieldHasError = true
              return t('graph.errors.graph_height_too_low')
            }

            if (parseInt(graphHeight) > 1000) {
              _fieldHasError = true
              return t('graph.errors.graph_height_too_high')
            }
          })()}
          onChange={ev => {
            // Update the graph height. We don't pass this into the search params because it's not a parameter that
            // affects the graph itself, but rather the container that the graph is rendered in.
            setGraphHeight(ev.target.value)
          }}
        />
        <Button
          data-testid='download-csv'
          onClick={() => downloadCsvFile(linesToCsv(data.lines, input, output), `graph_data_${formula.ident}`)}
        >
          {t('graph.download_graph')}
        </Button>
      </GraphDataControls>
      <ConstantsTitle>{t('graph.constants')}</ConstantsTitle>
      {lines.map((line, key1) => (
        <ConstantsContainer key={key1}>
          <ConstantsHeader>
            <ConstantsNameField
              type='text'
              data-testid='line-name'
              label={t('graph.line_name')}
              value={line.name}
              format={() => true}
              onChange={ev => {
                // Update the name of the line.
                const newName = ev.target.value
                const newLines = cloneDeep(lines)
                newLines[key1].name = newName
                updateSearchParams(navigate, inputRange, iterations, input, output, newLines, title, xAxis, yAxis)
              }}
            />
            {/* Hide delete constants button when there is only one set of constants. */}
            {lines.length > 1 && (
              <DeleteConstantsButton
                data-testid={`delete-constants-button-${key1}`}
                onClick={() => {
                  const newLines = cloneDeep(lines)
                  newLines.splice(key1, 1)
                  updateSearchParams(navigate, inputRange, iterations, input, output, newLines, title, xAxis, yAxis)
                }}
              >
                <DeleteIcon icon={faXmark} />
              </DeleteConstantsButton>
            )}
          </ConstantsHeader>
          <ConstantsSeparator />
          <Constants>
            {Object.entries(line.constants).map(([name, constantValue], key2) => {
              // XXX: ignores CHOICE type
              const arg = formula.arguments[name]
              const value = constantValue.value
              return (
                arg.argumentType === 'UNIT' && (
                  <ConstantSliderField
                    data-testid={`${key1}-constant-${name}-${constantValue.type}`}
                    label={name}
                    tooltip={tooltip(arg)}
                    units={arg.units}
                    valueType={arg}
                    constantValue={constantValue}
                    error={(() => {
                      if (constantValue.type == 'slider') {
                        const { lowerBound, upperBound } = constantValue

                        // Error handling for the bounds in the slider field.
                        if (lowerBound === '' || upperBound === '') {
                          _fieldHasError = true
                          return i18n.t('graph.errors.no_value')
                        }

                        if (parseFloat(lowerBound) >= parseFloat(upperBound)) {
                          _fieldHasError = true
                          return i18n.t('graph.errors.bounds')
                        }

                        if (parseFloat(lowerBound) < arg.physicalRange[0]) {
                          _fieldHasError = true
                          return i18n.t('graph.errors.bound_below_physical_min', { min: arg.physicalRange[0] })
                        }

                        if (parseFloat(upperBound) > arg.physicalRange[1]) {
                          _fieldHasError = true
                          return i18n.t('graph.errors.bound_above_physical_max', { max: arg.physicalRange[1] })
                        }
                      }

                      // Error handling for the value in the constant field or the value of the slider in the slider field.
                      if (value == '') {
                        _fieldHasError = true
                        return t('graph.errors.no_value')
                      }

                      if (Number.isNaN(parseFloat(value))) {
                        _fieldHasError = true
                        return t('graph.errors.nan')
                      }

                      if (parseFloat(value) < arg.physicalRange[0] || parseFloat(value) > arg.physicalRange[1]) {
                        _fieldHasError = true
                        return t('graph.errors.out_of_range', {
                          range: `(${arg.physicalRange[0]}, ${arg.physicalRange[1]})`,
                        })
                      }
                    })()}
                    warning={(() => {
                      if (constantValue.type == 'slider' && arg.tentativeRange !== undefined) {
                        const { lowerBound, upperBound } = constantValue
                        // Warning handling for the bounds in the slider field.
                        if (parseFloat(lowerBound) < arg.tentativeRange[0]) {
                          return i18n.t('graph.errors.bound_below_tentative_min', { min: arg.tentativeRange[0] })
                        }

                        if (parseFloat(upperBound) > arg.tentativeRange[1]) {
                          return i18n.t('graph.errors.bound_above_tentative_max', { max: arg.tentativeRange[1] })
                        }
                      }

                      // Warning handling for the value in the constant field or the value of the slider in the slider field.
                      if (
                        arg.tentativeRange !== undefined &&
                        (parseFloat(value) < arg.tentativeRange[0] || parseFloat(value) > arg.tentativeRange[1])
                      ) {
                        return t('graph.errors.out_of_range', {
                          range: `(${arg.tentativeRange[0]}, ${arg.tentativeRange[1]})`,
                        })
                      }
                    })()}
                    onChange={constantValue => {
                      // Ensure that the value of the constant is within the bounds of the slider.
                      if (constantValue.type == 'slider') {
                        if (constantValue.value < constantValue.lowerBound) {
                          constantValue.value = constantValue.lowerBound
                        } else if (constantValue.value > constantValue.upperBound) {
                          constantValue.value = constantValue.upperBound
                        }
                      }

                      // Update the value of the constant.
                      const newLines = cloneDeep(lines)
                      newLines[key1].constants[name] = constantValue
                      updateSearchParams(navigate, inputRange, iterations, input, output, newLines, title, xAxis, yAxis)
                    }}
                    key={key2}
                  />
                )
              )
            })}
            <DownloadLineButton
              data-testid={`download-csv-line-${line.name.replace(' ', '_')}`}
              onClick={() =>
                downloadCsvFile(
                  linesToCsv([data.lines[key1]], input, output),
                  `graph_${formula.ident}_${line.name.replace(' ', '_')}`
                )
              }
            >
              {t('graph.download_line')}
            </DownloadLineButton>
          </Constants>
        </ConstantsContainer>
      ))}
      <AddConstantsButton
        data-testid='add-constants-button'
        icon={faPlusCircle}
        onClick={() => {
          // Add a new line.
          const newLines = [...cloneDeep(lines), cloneDeep(lines[lines.length - 1])]
          updateSearchParams(navigate, inputRange, iterations, input, output, newLines, title, xAxis, yAxis)
        }}
      />
    </Container>
  )

  // Update the fieldHasError state if it was changed.
  if (_fieldHasError !== fieldHasError) {
    setFieldHasError(_fieldHasError)
  }

  return out
}
