import config from 'config'
import { PyodideInterface } from 'pyodide'
import { PyCallable, PyProxy, PyProxyWithGet } from 'pyodide/ffi'

/**
 * Converts a result from Pyodide to a JavaScript value.
 * Needed because Pyodide sometimes returns a PyProxy instead of a JavaScript value.
 * This function extracts the JavaScript value from the PyProxy if it is one.
 *
 * @param result The result from Pyodide.
 * @returns The result converted to a JavaScript value.
 */
export function pyodideResultToJs<T>(result: NotPromise<T>): unknown {
  if (typeof result === 'object' && result !== null && ('toJs' in result || 'target' in result)) {
    return (result as unknown as PyProxy).toJs()
  }

  return result
}

/**
 * Creates a Python dictionary.
 *
 * Needed to create the ExecutionEnvironment object.
 *
 * @param pyodideInterface The Pyodide interface to use.
 * @returns The created Python dictionary.
 */
export function createPythonDictionary(pyodideInterface: PyodideInterface): PyProxy {
  // Simply calls `dict()` in Python.
  const pyGlobals = pyodideInterface.globals as PyProxyWithGet
  const dictClass = pyGlobals.get('dict') as PyCallable
  return dictClass() as PyProxy
}

/**
 * Regex to match a semantic version number.
 * Needed to extract the version number from the wheel filename in order to fetch the latest wheel.
 */
const semVerRegex = /(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:\.(?:0|[a-zA-Z]*(\d*)))?/

/**
 * Information about a semantic version number.
 */
type SemVerInfo = { major: number; minor: number; patch: number; dev: number | undefined }

/**
 * Extracts the semantic version number from the given string.
 *
 * @param fullText The string to extract the version number from.
 * @returns The extracted version number or null if the string does not contain a version number.
 */
function getSemVersionInfo(fullText: string): SemVerInfo | null {
  const result = semVerRegex.exec(fullText)
  if (result == null) return null
  return {
    major: parseInt(result[1]),
    minor: parseInt(result[2]),
    patch: parseInt(result[3]),
    dev: result[4] ? parseInt(result[4]) : undefined,
  }
}

/**
 * Compares two semantic version numbers.
 *
 * @param version The version to compare.
 * @param base The base version to compare to.
 * @returns 1 if the first version is greater than the second, -1 if it is smaller and 0 if they are equal.
 */
function compareSemVersion(version: SemVerInfo, base: SemVerInfo) {
  if (version.major > base.major) return 1
  if (version.major < base.major) return -1

  if (version.minor > base.minor) return 1
  if (version.minor < base.minor) return -1

  if (version.patch > base.patch) return 1
  if (version.patch < base.patch) return -1

  if (version.dev === undefined && base.dev !== undefined) return 1
  if (version.dev !== undefined && base.dev === undefined) return -1
  if (version.dev === undefined && base.dev === undefined) return 0

  if ((version.dev as number) > (base.dev as number)) return 1
  if ((version.dev as number) < (base.dev as number)) return -1
  return 0
}

/**
 * Fetches the toolkit registry and finds the latest wheel available, then returns the link to it.
 *
 * @param registryUrl The URL of the proxy registry.
 * @returns The link to the latest wheel.
 */
export async function getLatestWheelFromRegistry(registryUrl: string): Promise<string> {
  const html = await (await fetch(registryUrl)).text()

  // Parse the HTML to find the latest

  const parser = new DOMParser()
  const document = parser.parseFromString(html, 'text/html')

  // Due to the ordering of the DOM entries, the wheel is the second to last element
  const links = document.body.getElementsByTagName('a')
  const versions = Array.from(links)
    .filter((element: HTMLAnchorElement) => {
      const segments = element.innerText.split('.')
      if (segments[segments.length - 1] === 'whl') return true
      return false
    })
    .map((element: HTMLAnchorElement) => ({
      link: element.href.split('#')[0],
      version: getSemVersionInfo(element.innerText),
      name: element.innerText,
    }))
    // Filter out null, or dev versions (if in production)
    .filter(info => info.version !== null && (!config.production || info.version.dev === undefined)) as {
    link: string
    version: SemVerInfo
    name: string
  }[]

  if (versions.length == 0) {
    throw new Error('Could not load toolkit, registry has no valid wheel.')
  }

  const latestVersion = versions.reduce((prev, curr) => {
    return compareSemVersion(curr.version, prev.version) == 1 ? curr : prev
  })

  return latestVersion.link
}
