import CondensedFormula from './CondensedFormula'
import TestCase from './TestCase'
import ValueType from './ValueType'

/**
 * A Formula is a CondensedFormula with additional information.
 * It is used to display a formula in the formula page and the graph page.
 *
 * It consists of the Python script that must be executed to compute the formula,
 * and other crucial information such as the documentation, the test cases, and the formula type.
 */
type Formula = CondensedFormula & {
  script: string
  documentation: string
  testSource: string
  testCases: TestCase[]
  formulaType: 'PureFormula' | 'ImpureFormula'
  notebookPath: string
  arguments: { [index: string]: ValueType }
}

namespace Formula {
  /**
   * Checks if the given JSON object is a valid Formula.
   * @param json The JSON object to check.
   * @throws SyntaxError if the given JSON object is not a valid Formula.
   */
  export function isFormula(json: unknown): asserts json is Formula {
    CondensedFormula.isCondensedFormula(json)

    if (!('documentation' in json)) {
      throw SyntaxError('bad Formula format: missing property documentation')
    }

    if (typeof json.documentation !== 'string') {
      throw SyntaxError('bad Formula format: property documentation is not a string')
    }

    if (!('testSource' in json)) {
      throw SyntaxError('bad Formula format: missing property testSource')
    }

    if (typeof json.testSource !== 'string') {
      throw SyntaxError('bad Formula format: property testSource is not a string')
    }

    if (!('testCases' in json)) {
      throw SyntaxError('bad Formula format: missing property keywords')
    }

    if (!Array.isArray(json.testCases)) {
      throw SyntaxError('bad Formula format: property testCases is not an array')
    }

    json.testCases.forEach(testCase => TestCase.isTestCase(testCase))

    if (!('formulaType' in json)) {
      throw SyntaxError('bad Formula format: missing property formulaType')
    }

    if (typeof json.formulaType !== 'string') {
      throw SyntaxError('bad Formula format: property formulaType is not a string')
    }

    if (!('arguments' in json)) {
      throw SyntaxError('bad Formula format: missing property arguments')
    }

    if (typeof json.arguments !== 'object' || json.arguments === null) {
      throw SyntaxError('bad Formula format: property arguments is not an object')
    }

    Object.values(json.arguments).forEach(value => ValueType.isValueType(value))

    if (!('notebookPath' in json)) {
      throw SyntaxError('bad Formula format: missing property notebookPath')
    }

    if (typeof json.notebookPath !== 'string') {
      throw SyntaxError('bad Formula format: property notebookPath is not a string')
    }
  }

  /**
   * Creates a Formula from the given JSON object.
   * @param json The JSON object to create the Formula from.
   * @param script The Python script that must be executed to compute the formula.
   *               This is a separate parameter because it is not included in the JSON object.
   *               It is retrieved with a separate request.
   * @throws SyntaxError if the given JSON object is not a valid Formula.
   * @returns The created Formula.
   */
  export function fromJson(json: unknown, script: string): Formula {
    isFormula(json)

    return { ...json, script }
  }
}

export default Formula
