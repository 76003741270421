export const sizes = {
  xs: 320,
  sm: 540,
  md: 900,
  lg: 1024,
  xl: 1440,
}

const breakpoints = {
  xs: `(max-width: ${sizes.xs}px)`,
  sm: `(max-width: ${sizes.sm}px)`,
  md: `(max-width: ${sizes.md}px)`,
  lg: `(max-width: ${sizes.lg}px)`,
  xl: `(max-width: ${sizes.xl}px)`,
  xsMin: `(min-width: ${sizes.xs + 1}px)`,
  smMin: `(min-width: ${sizes.sm + 1}px)`,
  mdMin: `(min-width: ${sizes.md + 1}px)`,
  lgMin: `(min-width: ${sizes.lg + 1}px)`,
  xlMin: `(min-width: ${sizes.xl + 1}px)`,
  desktop: `(min-width: ${sizes.sm + 1}px)`,
}

export default breakpoints
