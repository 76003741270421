import Footer from 'components/Footer'
import Navbar from 'components/Navbar'
import useStickyState from 'hooks/useStickyState'
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import themes, { ThemeColor, ThemeContext } from 'themes'

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
`

export const ThemeWrapper = styled.div<{ colorTheme: keyof typeof themes }>`
  --color-error: #f93c30;
  --color-warning: #ebba0f;

  ${props => `${Object.entries(themes[props.colorTheme]).map(([k, v]) => `${k}: ${v};`)}`.replaceAll(',', '\n')}

  background: var(${ThemeColor.Primary});
  transition: background-color 0.2s;

  & * {
    outline-color: var(${ThemeColor.Accent});
    /* transition: outline-color 0.2s; */
  }
`

/**
 * The Root view.
 *
 * This view holds the page shell, which includes the navbar, footer, and the main content.
 * The main content is rendered by the router, and is the only child of the `Outlet` component.
 */
export default function Root() {
  const location = useLocation()
  const [colorTheme, setColorTheme] = useStickyState<keyof typeof themes>(
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
    'colorTheme'
  )

  return (
    <ThemeContext.Provider value={colorTheme}>
      <ThemeWrapper colorTheme={colorTheme} data-testid={`root-${colorTheme}`}>
        <Navbar
          home={location.pathname === '/'}
          searchText={
            location.pathname === '/search' ? new URLSearchParams(location.search).get('q') ?? undefined : undefined
          }
          changeTheme={() => setColorTheme(colorTheme => (colorTheme === 'dark' ? 'light' : 'dark'))}
        />
        <Container>
          <Outlet /> {/* View goes here */}
          <Footer />
        </Container>
        <ScrollRestoration />
      </ThemeWrapper>
    </ThemeContext.Provider>
  )
}
