import styled from 'styled-components'
import { ThemeColor } from 'themes'

const Container = styled.div`
  background: var(${ThemeColor.Secondary});
  color: var(${ThemeColor.TextSecondary});
  border-radius: 10px;
  flex-grow: 0 !important;
  transition: color 0.2s, background-color 0.2s, outline-color 0.2s;

  &:focus-within {
    outline: var(${ThemeColor.Accent}) solid 2px;
  }

  & * {
    cursor: pointer;
  }
`

const Label = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 8px 8px 16px;
  gap: 16px;
`

const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`

const Checkmark = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
  background-color: var(${ThemeColor.Tertiary});
  color: var(${ThemeColor.Text});
  transition: color 0.2s, background-color 0.2s;
  border-radius: 5px;

  &::after {
    content: '';
    display: none;
    width: 8px;
    height: 16px;
    border: solid var(${ThemeColor.TextSecondary});
    border-width: 0 3px 3px 0;
    transform: translateY(-2px) rotate(45deg);
    transition: border-color 0.2s;
  }

  ${CheckboxInput}:checked ~ ${Label} &::after {
    display: block;
  }
`

type CheckboxProps = {
  label: string
  checked: boolean
  onChange: (checked: boolean) => void
  id: string
  className?: string
}

/**
 * A custom styled checkbox component.
 * @param label The label to display next to the checkbox.
 * @param checked Whether the checkbox is checked.
 * @param onChange The function to call when the checkbox is toggled.
 * @param id Controls the `name` and `id` of the checkbox and the data-testid.
 */
export default function Checkbox({ label, checked, onChange, id, className }: CheckboxProps) {
  return (
    <Container className={className}>
      <CheckboxInput
        name={id}
        id={id}
        data-testid={`${id}-checkbox`}
        checked={checked}
        onChange={ev => onChange(ev.target.checked)}
      />
      <Label htmlFor={id}>
        {label}
        <Checkmark />
      </Label>
    </Container>
  )
}
