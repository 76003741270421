/**
 * An entry in a choice list of an enum value type.
 */
type ChoiceEntry = {
  value: string
  name: string
  description?: string
}

namespace ChoiceEntry {
  /**
   * Checks if the given JSON object is a valid ChoiceEntry.
   * @param json The JSON object to check.
   * @throws SyntaxError if the given JSON object is not a valid ChoiceEntry.
   */
  export function isChoiceEntry(json: unknown): asserts json is ToJson<ChoiceEntry> {
    if (typeof json !== 'object' || json === null) throw SyntaxError('bad ChoiceEntry format: not an object')

    if (!('value' in json)) throw SyntaxError('bad ChoiceEntry format: missing property value')

    if (typeof json.value !== 'string') throw SyntaxError('bad ChoiceEntry format: property value is not a string')

    if (!('name' in json)) throw SyntaxError('bad ChoiceEntry format: missing property name')

    if (typeof json.name !== 'string') throw SyntaxError('bad ChoiceEntry format: property name is not a string')

    if ('description' in json && typeof json.description !== 'string' && json.description !== null)
      throw SyntaxError('bad ChoiceEntry format: property description is not a string')
  }

  /**
   * Creates a ChoiceEntry from the given JSON object.
   * @param json The JSON object to load from.
   * @throws SyntaxError if the given JSON object is not a valid ChoiceEntry.
   * @returns The created ChoiceEntry.
   */
  export function fromJson(json: unknown): ChoiceEntry {
    isChoiceEntry(json)

    return {
      value: json.value,
      name: json.name,
      description: json.description,
    }
  }
}

export default ChoiceEntry
