import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { ReactNode, forwardRef, useImperativeHandle, useRef, useState } from 'react'
import styled from 'styled-components'
import { ThemeColor } from 'themes'
import ClickIcon from './ClickIcon'

const Dialog = styled.dialog`
  background: var(${ThemeColor.Secondary});
  color: var(${ThemeColor.TextSecondary});
  border: 1px solid var(${ThemeColor.Tertiary});
  border-radius: 10px !important;
  outline: none;
  transition: color 0.2s, background-color 0.2s, border-color 0.2s;
`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 20px 30px;
  white-space: pre-line;
`

const CloseButton = styled(ClickIcon)`
  position: absolute;
  top: 0;
  right: 5px;
  font-size: 1.5rem;

  &:hover {
    color: var(${ThemeColor.Error});
  }
`

const Title = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: var(${ThemeColor.Text});
  transition: color 0.2s;
`

export type ModalRef = {
  close: () => void
  show: () => void
  showModal: () => void
}

/**
 * A modal dialog that can be shown and hidden programmatically.
 *
 * @param props.title The title of the dialog.
 * @param props.onClose A callback that is called when the dialog is closed.
 * @param props.children The content of the dialog.
 */
const Modal = forwardRef<
  ModalRef,
  {
    title?: ReactNode
    onClose?: () => void
    children?: ReactNode
    'data-testid'?: string
  }
>(function Modal(props, ref) {
  const dialogRef = useRef<HTMLDialogElement>(null)
  const [modal, setModal] = useState(false)
  useImperativeHandle(ref, () => ({
    close: () => dialogRef?.current?.close(),
    show: () => {
      setModal(false)
      dialogRef?.current?.show()
    },
    showModal: () => {
      setModal(true)
      dialogRef?.current?.showModal()
    },
  }))

  return (
    <Dialog ref={dialogRef} onClose={props.onClose} data-testid={props['data-testid']}>
      <Container>
        {modal && <CloseButton icon={faXmark} onClick={() => dialogRef?.current?.close()} />}
        {props.title && <Title>{props.title}</Title>}
        {props.children}
      </Container>
    </Dialog>
  )
})

export default Modal
