import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { MouseEventHandler } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import styled from 'styled-components'
import { ThemeColor } from 'themes'

const Container = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-items: center;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  color: ${props => (props.disabled ? `var(${ThemeColor.Tertiary})` : `var(${ThemeColor.TextSecondary})`)};
  transition: color 0.2s;

  &:hover {
    color: ${props => (props.disabled ? `var(${ThemeColor.Tertiary})` : `var(${ThemeColor.Accent})`)};
  }
`

/**
 * Component for displaying a clickable icon.
 *
 * @param icon The FontAwesome icon to display.
 * @param onClick The function to be called when the icon is clicked.
 * @param to The path to navigate to when the icon is clicked.
 * @param disabled Whether the icon is disabled.
 */
export default function ClickIcon({
  className,
  disabled,
  onClick,
  reloadDocument,
  replace,
  state,
  preventScrollReset,
  relative,
  to,
  ariaLabel,
  ...iconProps
}: { disabled?: boolean; ariaLabel?: string } & (
  | (Omit<FontAwesomeIconProps & LinkProps, 'onClick'> & { onClick?: undefined })
  | (Omit<FontAwesomeIconProps, 'onClick'> & { onClick?: MouseEventHandler<HTMLDivElement> } & {
      reloadDocument?: undefined
      replace?: undefined
      state?: undefined
      preventScrollReset?: undefined
      relative?: undefined
      to?: undefined
    })
)) {
  if (!disabled && to) {
    // Render as a navigatable link.
    return (
      <Link
        reloadDocument={reloadDocument}
        replace={replace}
        state={state}
        preventScrollReset={preventScrollReset}
        relative={relative}
        to={to}
        aria-label={ariaLabel}
      >
        <Container data-testid={`click-icon`} className={className} disabled={disabled}>
          <FontAwesomeIcon {...iconProps} />
        </Container>
      </Link>
    )
  }

  // Render as a clickable icon.
  return (
    <Container
      data-testid={`click-icon`}
      className={className}
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
      tabIndex={0}
      aria-label={ariaLabel}
    >
      <FontAwesomeIcon {...iconProps} />
    </Container>
  )
}
