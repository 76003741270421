import App from 'App'
import 'assets/scss/main.scss'
import pyodideService from 'domain/services/PyodideServiceProxy'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

if (process.env.NODE_ENV === 'development') {
  globalThis.pyodideService = pyodideService
}

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <StrictMode>
    <App />
  </StrictMode>
)
