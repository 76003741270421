import styled from 'styled-components'
import { ThemeColor } from 'themes'

const Container = styled.div`
  max-width: calc(100vw - 60px);
  overflow: auto;
  grid-column: 1 / -1;
  justify-self: center;
  color: var(${ThemeColor.Text});
  transition: color 0.2s;
`

const InnerTable = styled.table`
  border-radius: 10px;
  overflow: hidden;
  border-spacing: 0;

  & th,
  & td {
    border: solid 1px var(${ThemeColor.Tertiary});
    padding: 5px 10px;
    transition: border-color 0.2s;
  }

  & thead {
    background: var(${ThemeColor.Secondary});
    border-radius: 10px 10px 0 0;
    transition: background-color 0.2s;
  }

  & th:first-child {
    border-radius: 10px 0 0 0;
  }

  & th:last-child {
    border-radius: 0 10px 0 0;
  }

  & :not(thead) tr:last-of-type {
    border-radius: 0 0 10px 10px;

    td:first-child {
      border-radius: 0 0 0 10px;
    }

    td:last-child {
      border-radius: 0 0 10px 0;
    }
  }
`

/**
 * Styled HTML table.
 */
export default function Table(props: React.HTMLAttributes<HTMLTableElement>) {
  return (
    <Container>
      <InnerTable {...props} />
    </Container>
  )
}
