import { AxisType } from 'plotly.js'

namespace ConstantValue {
  export type Slider = {
    type: 'slider'
    value: string
    lowerBound: string
    upperBound: string
  }

  export type Input = {
    type: 'input'
    value: string
  }
}

/**
 * The value of a constant.
 * Can be either a slider value or an input value.
 */
export type ConstantValue = ConstantValue.Slider | ConstantValue.Input

/**
 * The constants of a line.
 * This is a mapping of constant input names to their values.
 */
export type Constants = {
  [argName: string]: ConstantValue
}

/**
 * A graphed line. Contains the name and the values of the constants.
 */
export type Line = {
  name: string
  constants: Constants
}

/**
 * The settings for the axes of a graph.
 */
export type AxisSettings = {
  label: string
  type: AxisType
}

/**
 * The settings for a graph.
 * This is the object that is encoded in the URL to make it shareable.
 * Consists of all the settings for the graph that need to be saved for sharing.
 */
type GraphSettings = {
  min: string
  max: string
  iterations: string
  input: string
  output: string
  lines: Line[]
  title?: string
  xAxis?: AxisSettings
  yAxis?: AxisSettings
  lineSmoothing?: boolean
}

namespace GraphSettings {
  /**
   * Checks if the given object is a valid GraphSettings object.
   * @param json The object to check.
   * @throws SyntaxError if the given object is not a valid GraphSettings object.
   */
  export function isGraphSearchParams(json: unknown): asserts json is GraphSettings {
    if (typeof json !== 'object' || json === null) {
      throw SyntaxError('bad GraphSearchParams format: not an object')
    }

    if (!('min' in json)) {
      throw SyntaxError('bad GraphSearchParams format: missing property min')
    }

    if (typeof json.min !== 'string') {
      throw SyntaxError('bad GraphSearchParams format: property min is not a string')
    }

    if (!('max' in json)) {
      throw SyntaxError('bad GraphSearchParams format: missing property max')
    }

    if (typeof json.max !== 'string') {
      throw SyntaxError('bad GraphSearchParams format: property max is not a string')
    }

    if (!('iterations' in json)) {
      throw SyntaxError('bad GraphSearchParams format: missing property iterations')
    }

    if (typeof json.iterations !== 'string') {
      throw SyntaxError('bad GraphSearchParams format: property iterations is not a string')
    }

    if (!('input' in json)) {
      throw SyntaxError('bad GraphSearchParams format: missing property input')
    }

    if (typeof json.input !== 'string') {
      throw SyntaxError('bad GraphSearchParams format: property input is not a string')
    }

    if (!('output' in json)) {
      throw SyntaxError('bad GraphSearchParams format: missing property output')
    }

    if (typeof json.output !== 'string') {
      throw SyntaxError('bad GraphSearchParams format: property output is not a string')
    }

    if (!('lines' in json)) {
      throw SyntaxError('bad GraphSearchParams format: missing property lines')
    }

    if (!Array.isArray(json.lines)) {
      throw SyntaxError('bad GraphSearchParams format: property lines is not an array')
    }

    json.lines.forEach(line => {
      if (typeof line !== 'object' || line === null) {
        throw SyntaxError('bad GraphSearchParams format: line is not an object')
      }

      if (!('name' in line)) {
        throw SyntaxError('bad GraphSearchParams format: missing property name')
      }

      if (typeof line.name !== 'string') {
        throw SyntaxError('bad GraphSearchParams format: property name is not a string')
      }

      if (!('constants' in line)) {
        throw SyntaxError('bad GraphSearchParams format: missing property constants')
      }

      if (typeof line.constants !== 'object' || line.constants === null) {
        throw SyntaxError('bad GraphSearchParams format: property constants is not an object')
      }

      Object.values(line.constants).forEach(argValue => {
        if (typeof argValue !== 'object' || argValue === null || Array.isArray(argValue)) {
          throw SyntaxError('bad GraphSearchParams format: argValue is not an object')
        }

        if (!('type' in argValue)) {
          throw SyntaxError('bad GraphSearchParams format: missing property type')
        }

        if (typeof argValue.type !== 'string') {
          throw SyntaxError('bad GraphSearchParams format: property type is not a string')
        }

        if (!('value' in argValue)) {
          throw SyntaxError('bad GraphSearchParams format: missing property value')
        }

        if (typeof argValue.value !== 'string') {
          throw SyntaxError('bad GraphSearchParams format: property value is not a string')
        }

        if (argValue.type === 'slider') {
          if (!('lowerBound' in argValue)) {
            throw SyntaxError('bad GraphSearchParams format: missing property lowerBound')
          }

          if (typeof argValue.lowerBound !== 'string') {
            throw SyntaxError('bad GraphSearchParams format: property lowerBound is not a string')
          }

          if (!('upperBound' in argValue)) {
            throw SyntaxError('bad GraphSearchParams format: missing property upperBound')
          }

          if (typeof argValue.upperBound !== 'string') {
            throw SyntaxError('bad GraphSearchParams format: property upperBound is not a string')
          }
        }
      })
    })

    if ('title' in json) {
      if (typeof json.title !== 'string') {
        throw SyntaxError('bad GraphSearchParams format: property title is not a string')
      }
    }

    if ('xAxis' in json) {
      if (typeof json.xAxis !== 'object' || json.xAxis === null) {
        throw SyntaxError('bad GraphSearchParams format: property xAxis is not an object')
      }

      if (!('label' in json.xAxis)) {
        throw SyntaxError('bad GraphSearchParams format: missing property label')
      }

      if (typeof json.xAxis.label !== 'string') {
        throw SyntaxError('bad GraphSearchParams format: property label is not a string')
      }

      if (!('type' in json.xAxis)) {
        throw SyntaxError('bad GraphSearchParams format: missing property type')
      }

      if (typeof json.xAxis.type !== 'string') {
        throw SyntaxError('bad GraphSearchParams format: property type is not a string')
      }
    }

    if ('yAxis' in json) {
      if (typeof json.yAxis !== 'object' || json.yAxis === null) {
        throw SyntaxError('bad GraphSearchParams format: property yAxis is not an object')
      }

      if (!('label' in json.yAxis)) {
        throw SyntaxError('bad GraphSearchParams format: missing property label')
      }

      if (typeof json.yAxis.label !== 'string') {
        throw SyntaxError('bad GraphSearchParams format: property label is not a string')
      }

      if (!('type' in json.yAxis)) {
        throw SyntaxError('bad GraphSearchParams format: missing property type')
      }

      if (typeof json.yAxis.type !== 'string') {
        throw SyntaxError('bad GraphSearchParams format: property type is not a string')
      }
    }

    if ('lineSmoothing' in json) {
      if (typeof json.lineSmoothing !== 'boolean') {
        throw SyntaxError('bad GraphSearchParams format: property lineSmoothing is not a boolean')
      }
    }
  }

  /**
   * Creates a GraphSettings object from the given JSON object.
   * @param json The JSON object to create the GraphSettings object from.
   * @throws SyntaxError if the given object is not a valid GraphSettings object.
   * @returns The created GraphSettings object.
   */
  export function fromJson(json: unknown): GraphSettings {
    isGraphSearchParams(json)

    return json
  }
}

export default GraphSettings
