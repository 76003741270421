import config from 'config'
import { ExecutionEnvironmentId } from 'domain/entities/ExecutionEnvironment'
import Formula from 'domain/entities/Formula'
import { Line } from 'domain/entities/GraphSettings'
import { getLatestWheelFromRegistry } from 'util/pyodideUtils'
import WebWorkerWrapper from './WebWorkerWrapper'
import { GraphLine, WebWorkerMessage } from './WorkerPyodideService'

/**
 * Class to communicate with the WorkerPyodideService.
 * The WorkerPyodideService is a WebWorker that runs Pyodide.
 * This class proxies the communication to the WebWorker using WebWorker communication.
 */
class PyodideServiceProxy {
  worker: WebWorkerWrapper<WebWorkerMessage>

  /**
   * Creates a new PyodideServiceProxy.
   * Loads the WebWorker script and loads the latest version of the PyBox toolkit.
   * Then initializes the Pyodide execution engine.
   */
  constructor() {
    this.worker = new WebWorkerWrapper(new Worker(new URL('./worker.ts', import.meta.url)))
    getLatestWheelFromRegistry(config.registryUrl).then(wheelUrl => {
      this.worker.perform({ type: 'INIT', wheelUrl })
    })
  }

  /**
   * Creates a new ExecutionEnvironment in the WebWorker.
   * Every formula must have its own ExecutionEnvironment.
   * This is to ensure that the computations do not interfere with each other.
   *
   * @param formula The formula to create the ExecutionEnvironment for.
   * @returns The ID of the created ExecutionEnvironment.
   */
  async createExecutionEnvironment(formula: Formula): Promise<ExecutionEnvironmentId> {
    return this.worker.perform({ type: 'CREATE_ENVIRONMENT', formula }) as Promise<ExecutionEnvironmentId>
  }

  /**
   * Computes the output of a formula with the given inputs.
   *
   * @param environment The ID of the ExecutionEnvironment to compute the formula in.
   * @param args The inputs to the formula.
   * @returns The output of the formula.
   */
  async computeWithInputs(environment: ExecutionEnvironmentId, args: { [argName: string]: number | string }) {
    return this.worker.perform({ type: 'COMPUTE_INPUTS', environment, args })
  }

  /**
   * Computes the points of a graph.
   *
   * @param environment The ID of the ExecutionEnvironment to compute the graph points in.
   * @param input The name of the input variable.
   * @param output The name of the output variable.
   * @param inputRange The range of the input variable.
   * @param line The line to compute the points for. Consists of the constant values.
   * @param iterations The number of iterations to compute the points for.
   * @returns The points of the graph.
   */
  async computeGraphPoints(
    environment: ExecutionEnvironmentId,
    input: string,
    output: string,
    inputRange: [number, number],
    line: Line,
    iterations: number
  ): Promise<GraphLine> {
    return this.worker.perform({
      type: 'COMPUTE_GRAPH',
      environment,
      input,
      output,
      inputRange,
      line,
      iterations,
    }) as Promise<GraphLine>
  }
}

// Singleton instance of the PyodideServiceProxy.
export default new PyodideServiceProxy()
