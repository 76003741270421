import { ReactNode, forwardRef, useImperativeHandle, useRef } from 'react'
import styled from 'styled-components'
import { ThemeColor } from 'themes'
import Modal, { ModalRef } from './Modal'

const Item = styled.div`
  cursor: pointer;
  font-size: 1.2rem;
  transition: color 0.2s;

  &:hover {
    color: var(${ThemeColor.Accent});
  }
`

export type PickDialogRef = {
  close: () => void
  show: () => void
  showModal: () => void
}

/**
 * A pick dialog that can be shown and hidden programmatically.
 * The user can pick one of the options.
 *
 * @param props.title The title of the dialog.
 * @param props.options The options to display.
 * @param props.onClose A callback that is called when the dialog is closed.
 * @param props.onPick A callback that is called when the user picks an option.
 */
const PickDialog = forwardRef<
  PickDialogRef,
  { title: ReactNode; options: { [key: string]: string }; onClose?: () => void; onPick: (option: string) => void }
>(function PickDialog(props, ref) {
  const dialogRef = useRef<ModalRef>(null)
  useImperativeHandle(ref, () => ({
    close: () => dialogRef?.current?.close(),
    show: () => dialogRef?.current?.show(),
    showModal: () => dialogRef?.current?.showModal(),
  }))

  return (
    <Modal ref={dialogRef} onClose={props.onClose} title={props.title}>
      {Object.entries(props.options).map(([key, text]) => (
        <Item
          key={key}
          onClick={() => {
            props.onPick(key)
            dialogRef?.current?.close()
          }}
          data-testid='option'
        >
          {text}
        </Item>
      ))}
    </Modal>
  )
})

export default PickDialog
