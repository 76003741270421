import { Buffer } from 'buffer'

/**
 * Encodes a string to base64. Supports the full UTF-8 character set.
 * Needed to safely encode arbitrary strings for use in URLs.
 * Alternative is the builtin function btoa(), but that only supports ASCII.
 *
 * @param str The string to encode.
 * @returns The base64-encoded string.
 */
export const encodeBase64 = (str: string) => Buffer.from(str).toString('base64')

/**
 * Decodes a base64-encoded string. Supports the full UTF-8 character set.
 * Needed to safely decode arbitrary strings for use in URLs.
 * Alternative is the builtin function atob(), but that only supports ASCII.
 *
 * @param str The base64-encoded string to decode.
 * @returns The decoded string.
 */
export const decodeBase64 = (str: string) => Buffer.from(str, 'base64').toString()
