import { faArrowUpFromBracket, faChartLine, faRightLeft, faTrash } from '@fortawesome/free-solid-svg-icons'
import breakpoints from 'breakpoints'
import Button from 'components/Button'
import ClickIcon from 'components/ClickIcon'
import { SingleField } from 'components/Field'
import PickDialog, { PickDialogRef } from 'components/PickDialog'
import Separator from 'components/Separator'
import Table from 'components/Table'
import Tooltip from 'components/Tooltip'
import Computation from 'domain/entities/Computation'
import { ExecutionEnvironmentId } from 'domain/entities/ExecutionEnvironment'
import FieldsInfoFunctions, { FieldContent, FieldsInfo } from 'domain/entities/FieldsInfo'
import FormulaEntity from 'domain/entities/Formula'
import GraphSettings from 'domain/entities/GraphSettings'
import ValueType from 'domain/entities/ValueType'
import pyodideService from 'domain/services/PyodideServiceProxy'
import useStickyState from 'hooks/useStickyState'
import i18n from 'i18n'
import { TFunction } from 'i18next'
import cloneDeep from 'lodash/cloneDeep'
import { Dispatch, Fragment, SetStateAction, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavigateFunction, useLocation, useNavigate, useOutletContext } from 'react-router-dom'
import styled from 'styled-components'
import { ThemeColor } from 'themes'
import { decodeBase64, encodeBase64 } from 'util/encoding'
import downloadFile from 'util/fileUtils'
import { computeDefaultBounds, truncate } from 'util/numberUtils'
import { tooltip } from 'util/tooltipUtils'

const Margin = styled.div`
  display: grid;
  grid-template-columns: 1fr min(1100px, calc(100% - 60px)) 1fr;

  & > * {
    grid-column: 2;
  }
`

const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;

  @media ${breakpoints.md} {
    flex-direction: column;
  }
`

const Column = styled.div`
  display: grid;
  row-gap: 30px;
  width: 100%;
  height: min-content;
  display: grid;
  gap: 30px 10px;
  grid-column: 2;
  grid-template-columns: [buttons-start] auto [buttons-end field-start] auto [input-start] minmax(0, 1fr) auto [field-end];
  grid-auto-flow: dense;
  align-items: center;

  @media ${breakpoints.mdMin} {
    &:last-of-type {
      grid-column: 3;
      grid-template-columns: [field-start] auto [input-start] minmax(0, 1fr) auto [field-end buttons-start] auto [buttons-end];
    }
  }
`

const FieldButtons = styled.div`
  display: flex;
  gap: 10px;

  @media ${breakpoints.md} {
    flex-direction: column;

    ${Column}:last-of-type & {
      flex-direction: column-reverse;
    }
  }
`

const FieldButton = styled(ClickIcon)`
  font-size: 1.4rem;
`

const Field = styled(SingleField)`
  grid-template-columns: auto minmax(0, 1fr) auto;
  grid-column: field-start / field-end;
  gap: 0; /* cant believe this works */

  @supports (grid-template-columns: subgrid) {
    display: grid !important;
    grid-template-columns: subgrid;
  }

  @media ${breakpoints.sm} {
    display: flex !important;
  }

  /* hack because using TextBox glitches sometimes */
  & :nth-child(2) {
    grid-column-start: input-start;
  }

  & :last-child {
    grid-column-end: field-end;
  }
`

const OutputError = styled.div`
  width: 100%;
  grid-column: 1 / -3;
  text-align: center;
  color: var(${ThemeColor.Error});
  transition: color 0.2s;
`

const CSVContainer = styled.div`
  position: relative;
  width: 100%;
  grid-column: 1 / -2;
  display: flex;
  gap: 30px;
  align-items: stretch;

  @media ${breakpoints.md} {
    grid-column: 1 / -1;
  }

  @media ${breakpoints.sm} {
    flex-direction: column;
  }
`

const CSVInput = styled.input`
  display: none;
`

const ProcessCSV = styled(Button)`
  text-align: center;
`

const CSVTooltip = styled(Tooltip)`
  position: absolute;
  top: 50%;
  right: -15px;
  transform: translate(100%, -50%);

  @media ${breakpoints.md} {
    position: relative;
    top: 0;
    right: 0;
    transform: none;
  }
`

const AddToHistory = styled(Button)`
  grid-column: 1 / -2;

  @media ${breakpoints.md} {
    grid-column: 1 / -1;
  }
`

const History = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
`

const HistoryActions = styled.div`
  display: flex;
  gap: 12px;
`

const HistoryTitle = styled.h3`
  width: 100%;
  text-align: center;
  color: var(${ThemeColor.Text});
  transition: color 0.2s;
`

const HistoryValue = styled.p`
  text-decoration: underline;
  cursor: pointer;
`

const HistoryTableButtons = styled.div`
  display: flex;
  gap: 8px;
`

const DialogVar = styled.span`
  color: var(${ThemeColor.Accent});
  transition: color 0.2s;
`

/**
 * Generates the initial values of all inputs and outputs.
 * Values for unit types are initialised to empty strings.
 * Values for multiple choice types are the first available choice.
 *
 * @TODO In the future please incorporate the default feature here.
 *
 * @param args The arguments of the formula.
 * @returns The initial values of all inputs and outputs.
 */
export function generateFieldsInfo(args: { [index: string]: ValueType }): FieldsInfo {
  const inputs: { [name: string]: FieldContent } = {}
  const outputs: { [name: string]: FieldContent } = {}

  Object.entries(args).forEach(([name, valueType]) => {
    if (valueType.isDefaultOutput) {
      outputs[name] = { value: '' }
    } else {
      if (valueType.argumentType === 'CHOICE') {
        inputs[name] = { value: valueType.choices[0].value }
      } else {
        inputs[name] = { value: '' }
      }
    }
  })

  return { inputs, outputs }
}

/**
 * Updates the search URL without actually reloading the website.
 * Used so people can share formulas with the inputs pre-populated.
 *
 * @param navigate The navigate function from React Router.
 * @param fieldsInfo The fields info to encode and put in the URL.
 */
export const updateSearchParams = (navigate: NavigateFunction, fieldsInfo: FieldsInfo) => {
  const search = new URLSearchParams(location.search)
  search.set('params', encodeBase64(JSON.stringify(fieldsInfo)))
  navigate('?' + search.toString(), { replace: true, preventScrollReset: true })
}

/**
 * Loads the field values from the URL.
 *
 * @param search The URL search params.
 * @returns The field values.
 */
export const loadSearchParams = (search: string) => {
  const params = new URLSearchParams(search).get('params')

  if (params == null) {
    throw new Error('No params found')
  }

  return FieldsInfoFunctions.fromJson(JSON.parse(decodeBase64(params)))
}

/**
 * Handles the change of any input field. This function is called when the user
 * changes the value of an input field. It validates the input fields and
 * computes the outputs if the validation is successful.
 *
 * If any field is empty, the outputs are cleared. If any field is outside its
 * allowed physical range, the outputs are cleared and an error message is
 * displayed. A warning message is displayed if any field is outside its
 * tentative range, but still inside its physical range. If the validation is
 * successful, the outputs are computed using the `PyodideService` and
 * displayed in the output fields.
 *
 * If an exception occurs
 * during the execution of the Python code, the outputs are cleared and a
 * generic error message is displayed on the right column below the buttons.
 *
 * @param fieldsInfo The current values of all input and output fields.
 * @param environment The execution environment to use.
 * @param formula The formula to execute.
 * @returns The new values of all input and output fields.
 * @todo This function is very hard to understand. It should be refactored.
 *       Please see `Graph.tsx` for an example of how to properly validate
 *       input fields, compute outputs and display error messages.
 *       It also causes bugs when Pyodide does not return a value. This could
 *       happen when SymPy is not able to solve an equation. In this case,
 *       the website will just hang and the user will have to reload the page.
 *       Also for some reason on Safari the website also hangs when the Pyodide
 *       service throws an error.
 */
export async function onFieldChange(
  fieldsInfo: FieldsInfo,
  environment: ExecutionEnvironmentId,
  formula: FormulaEntity
) {
  const newFieldsInfo = cloneDeep(fieldsInfo)
  let allFieldsFilled = true
  let inputError = false

  const clearWarningsAndErrors = (key: string) => {
    delete newFieldsInfo.inputs[key].error
    delete newFieldsInfo.inputs[key].warning
  }

  const notFilled = (key: string) => {
    allFieldsFilled = false
    clearWarningsAndErrors(key)
  }

  // Main validation loop.
  // Goes through all input fields and validates them.
  const inputValues: { [index: string]: number | string } = {}
  for (const key in newFieldsInfo.inputs) {
    const arg = formula.arguments[key]

    if (arg == null) {
      // Could happen if the URL does not have the correct parameters.
      // In this case, we just ignore the parameter.
      continue
    }

    if (newFieldsInfo.inputs[key].value === '') {
      // Note down that not all fields are filled.
      // The computation will not continue.e
      notFilled(key)
    } else if (arg.argumentType === 'UNIT') {
      const variableValue = parseFloat(newFieldsInfo.inputs[key].value)

      if (isNaN(variableValue)) {
        notFilled(key)
        continue
      }

      if (variableValue < arg.physicalRange[0] || variableValue > arg.physicalRange[1]) {
        // Value is outside the physical range.

        // Clear all outputs.
        for (const key in newFieldsInfo.outputs) {
          newFieldsInfo.outputs[key].value = ''
        }

        // Show an error and note down that there is an error.
        // The computation will not continue.
        inputError = true
        newFieldsInfo.inputs[key].error = i18n.t('error.physical_range', {
          range: `(${arg.physicalRange[0]}, ${arg.physicalRange[1]})`,
        })
      } else if (
        arg.tentativeRange &&
        (variableValue < arg.tentativeRange[0] || variableValue > arg.tentativeRange[1])
      ) {
        // Value is outside the tentative range.
        // Show a warning and allow the computation to continue.
        newFieldsInfo.inputs[key].warning = i18n.t('error.tentative_range', {
          range: `(${arg.tentativeRange[0]}, ${arg.tentativeRange[1]})`,
        })
      } else {
        // Value is inside the physical range.
        clearWarningsAndErrors(key)
      }

      // Note down the value of the input.
      inputValues[key] = variableValue
    } else {
      clearWarningsAndErrors(key)
      inputValues[key] = newFieldsInfo.inputs[key].value
    }
  }

  if (allFieldsFilled && !inputError) {
    // All fields are filled and there are no errors.

    try {
      // Compute the outputs and update the output fields.
      const result = (await pyodideService.computeWithInputs(environment, inputValues)) as Map<
        string,
        number | string
      >[]
      result[0].forEach((value, key) => {
        newFieldsInfo.outputs[key].value = value.toString()
      })

      return { newFieldsInfo, canGraph: true }
    } catch (err) {
      // An exception occurred in Python during the computation.
      // Error message can be seen in the console in development, but is hidden
      // from the user, not to confuse them.

      /* istanbul ignore next */
      if (process.env.NODE_ENV === 'development') {
        console.error(err)
      }

      // Clear all outputs.
      for (const key in newFieldsInfo.outputs) {
        newFieldsInfo.outputs[key].value = ''
      }

      // Show a generic error message.
      let outputError = i18n.t('error.output_unavailable')
      if (err instanceof Error) {
        if (err.message.includes('ZeroDivisionError')) {
          outputError = i18n.t('error.division_by_zero')
        } else if (err.message.includes('toolkit.utils.RuntimeException')) {
          outputError = i18n.t('error.no_solution')
        }

        // TODO: Implement more error messages.
      }

      return { newFieldsInfo, outputError, canGraph: false }
    }
  }

  // Not all fields are filled or there is an error.
  for (const key in newFieldsInfo.outputs) {
    newFieldsInfo.outputs[key].value = ''
  }

  return { newFieldsInfo, canGraph: false }
}

/**
 * Generates the output CSV file for the given input CSV file.
 * The output CSV file contains the same columns as the input CSV file, plus
 * additional columns for the outputs of the formula.
 * The output CSV file is generated by executing the formula in the given
 * environment with the given input CSV file.
 * If an error occurs during the execution of the formula, the output CSV file
 * will contain rows with "Error" in the corresponding output columns.
 *
 * @param csvInput The uploaded filled-in template CSV file content.
 * @param environment The environment of the formula.
 * @param formula The formula to execute.
 * @returns An output CSV file containing the .
 */
export async function getOutputCSVfile(
  csvInput: string,
  environment: Promise<ExecutionEnvironmentId>,
  formula: FormulaEntity
) {
  const inputs = generateFieldsInfo(formula.arguments).inputs
  const csvHeaderInput = csvInput.slice(0, csvInput.indexOf('\n')).replaceAll(/[\r"]/g, '').split(',')

  const keys = Object.keys(inputs)
  const allVariables = Object.keys(formula.arguments)

  let error = ''

  // Check if the input CSV file has the correct header format.
  if (csvHeaderInput.length !== keys.length) return { outputCSV: [], error: i18n.t('fields.errors.wrong_format') }
  for (const h of csvHeaderInput) {
    if (!allVariables.includes(h)) {
      return { outputCSV: [], error: i18n.t('fields.errors.wrong_format') }
    }
  }

  // Get the rows of the input CSV file with input values.
  const csvRowsInput = csvInput
    .trim()
    .slice(csvInput.indexOf('\n') + 1) // +1 to skip the header row.
    .replaceAll(/[\r"]/g, '')
    .split('\n')

  const inputCollection = csvRowsInput.map(i => {
    const values = i.split(',')
    return Object.fromEntries(csvHeaderInput.map((name, j) => [name, values[j]]))
  })

  const outputHeaders = allVariables.filter(v => !csvHeaderInput.includes(v))
  const csvOutputRows = [outputHeaders.join(',')]

  // Run the computation for each row.
  for (const inputVariables of inputCollection) {
    try {
      const result = (await pyodideService.computeWithInputs(
        await environment,
        Object.fromEntries(
          Object.entries(inputVariables).map(([name, value]) => [
            name,
            formula.arguments[name].argumentType === 'UNIT' ? parseFloat(value) : value,
          ])
        )
      )) as Map<string, number | string>[]
      csvOutputRows.push(Array.from(result[0].values()).join(','))
    } catch (err) {
      // On error, show an error message and fill the output columns with "Error".
      const errorLine = []
      for (let j = 0; j < outputHeaders.length; j++) {
        errorLine.push('Error')
      }
      error = i18n.t('fields.errors.wrong_calculations')
      csvOutputRows.push(errorLine.join(','))
    }
  }

  // combine final CSV file. It has both inputs and the outputs.
  return { outputCSV: [csvHeaderInput.join(','), ...csvRowsInput].map((v, i) => `${v},${csvOutputRows[i]}`), error }
}

/**
 * Processes the input CSV file and downloads the output CSV file.
 *
 * @see getOutputCSVfile
 *
 * @param inputCSVFile The input CSV file.
 * @param environment The environment of the formula.
 * @param formula The formula to execute.
 * @param setOutputError A setter for the output error.
 * @param t The translation function.
 * @todo Add tests for this function.
 */
const processInputCSVFile = (
  inputCSVFile: File,
  environment: Promise<ExecutionEnvironmentId>,
  formula: FormulaEntity,
  setOutputError: Dispatch<SetStateAction<string | undefined>>,
  t: TFunction
) => {
  // Read the input CSV file.
  const fileReader = new FileReader()
  fileReader.onload = async function (event) {
    if (event.target) {
      const text = event.target.result
      if (text && typeof text === 'string') {
        // Generate the output CSV file.
        const { outputCSV, error } = await getOutputCSVfile(text, environment, formula)
        setOutputError(error)
        if (error !== t('fields.errors.wrong_format')) {
          // Download the output CSV file.
          downloadFile(`${t('fields.Output_of_')}${formula.name}.csv`, 'text/csv', outputCSV.join('\n'))
        }
      }
    }
  }

  // Trigger the reading of the input CSV file.
  // Calls the onload function defined above when the file is read.
  fileReader.readAsText(inputCSVFile)
}

/**
 * Adds the current computation to the computation history.
 *
 * @param fieldsInfo The current fields info.
 * @param setComputationHistory A setter for the computation history.
 */
const addToHistory = (fieldsInfo: FieldsInfo, setComputationHistory: Dispatch<SetStateAction<Computation[]>>) => {
  // Don't add to history if there are any empty fields.
  // This prevents adding erroneous computations to the history.
  if (Object.values(fieldsInfo.inputs).some(v => v.value === '')) return
  if (Object.values(fieldsInfo.outputs).some(v => v.value === '')) return

  const historyItem: Computation = {
    ...Object.fromEntries(Object.entries(fieldsInfo.inputs).map(([k, v]) => [k, v.value])),
    ...Object.fromEntries(Object.entries(fieldsInfo.outputs).map(([k, v]) => [k, v.value])),
  }

  setComputationHistory(prev => [...prev, historyItem])
}

function generateDialogOptions(formula: FormulaEntity, dialogOptions: keyof FieldsInfo, fieldsInfo: FieldsInfo) {
  return Object.fromEntries(
    Object.entries(formula.arguments)
      .filter(([argName, arg]) => arg.argumentType === 'UNIT' && argName in fieldsInfo[dialogOptions])
      .map(([k]) => [k, k])
  )
}

/**
 * Formula fields View.
 *
 * The formula view displays a single formula. It displays the inputs and outputs
 * in fields. The input fields are editable, and the outputs are read-only. When
 * all input fields are filled in, the outputs are computed automatically using
 * Pyodide, and displayed in the output fields. Documentation and test cases are
 * also displayed below the fields on the formula page.
 */
export default function Fields() {
  const { environment, formula }: { environment: Promise<ExecutionEnvironmentId>; formula: FormulaEntity } =
    useOutletContext()
  const [fieldsInfo, setFieldsInfo] = useState(() => generateFieldsInfo(formula.arguments))
  const [outputError, setOutputError] = useState<string | undefined>(undefined)
  const [canGraph, setCanGraph] = useState(false)
  const location = useLocation()

  const { t } = useTranslation()

  const dialogRef = useRef<PickDialogRef>(null)
  const [dialogType, setDialogType] = useState<'graph' | 'swap'>('graph')
  const [dialogOptions, setDialogOptions] = useState<keyof typeof fieldsInfo>('inputs')
  const [dialogSource, setDialogSource] = useState('')

  const [computationHistory, setComputationHistory] = useStickyState<Computation[]>(
    [],
    `fields_history_${formula.ident}`,
    sessionStorage
  )
  const COMPUTATION_HISTORY_DEFAULT_MAX_LENGTH = 5
  const [showMoreComputationHistory, setShowMoreComputationHistory] = useState(false)

  const navigate = useNavigate()

  /**
   * Triggers onFieldChange, and updates the fieldsInfo, the URL search parameters, outputError, and canGraph states.
   *
   * @see onFieldChange
   *
   * @param changeFieldsInfoTo The fieldsInfo to pass to onFieldChange.
   */
  const updateAllOnFieldChange = async (changeFieldsInfoTo: FieldsInfo) => {
    const { newFieldsInfo, outputError, canGraph } = await onFieldChange(changeFieldsInfoTo, await environment, formula)
    setFieldsInfo(newFieldsInfo)
    updateSearchParams(navigate, newFieldsInfo)
    setOutputError(outputError)
    setCanGraph(canGraph)
  }

  useEffect(() => {
    // When we reload the element, we want to try loading in from the search, otherwise we'll default
    // to generating some default search params
    let fieldsInfo

    try {
      fieldsInfo = loadSearchParams(location.search)
    } catch {
      fieldsInfo = generateFieldsInfo(formula.arguments)
      navigate('', { replace: true, preventScrollReset: true })
    }

    // Pyodide is still loading. Show a loading message.
    setOutputError('Loading pyodide...')

    // When the environment is ready, update the fields info.
    environment.then(() => setOutputError(''))
    updateAllOnFieldChange(fieldsInfo)
  }, [environment])

  return (
    <Margin>
      <Container>
        <Separator text='Inputs' breakpoint={breakpoints.md} />
        <Column>
          {Object.entries(formula.arguments).flatMap(
            ([name, info], i) =>
              fieldsInfo.inputs[name] && (
                <Fragment key={i}>
                  <FieldButtons>
                    {formula.formulaType === 'PureFormula' && (
                      // Swap button for inputs
                      <FieldButton
                        icon={faRightLeft}
                        // TODO: Add tests for this onClick handler.
                        onClick={async () => {
                          const outputs = Object.keys(fieldsInfo.outputs)
                          if (outputs.length === 1) {
                            // if there is only one output, swap without showing the dialog
                            const { [name]: inValue, ...freeInputs } = fieldsInfo.inputs
                            const { [outputs[0]]: outValue, ...freeOutputs } = fieldsInfo.outputs
                            updateAllOnFieldChange({
                              inputs: { ...freeInputs, [outputs[0]]: outValue },
                              outputs: { ...freeOutputs, [name]: inValue },
                            })
                          } else {
                            // otherwise let the user choose which output to swap with
                            setDialogType('swap')
                            setDialogOptions('outputs')
                            setDialogSource(name)
                            dialogRef.current?.showModal()
                          }
                        }}
                        data-testid={`swap-${name}`}
                      />
                    )}
                    {/* Graph button for inputs. */}
                    {info.argumentType === 'UNIT' &&
                      !!Object.keys(generateDialogOptions(formula, 'outputs', fieldsInfo)).length && (
                        <FieldButton
                          icon={faChartLine}
                          disabled={!canGraph}
                          // TODO: Add tests for this onClick handler.
                          onClick={() => {
                            // Let the user choose against which output to graph.
                            setDialogType('graph')
                            setDialogOptions('outputs')
                            setDialogSource(name)
                            dialogRef.current?.showModal()
                          }}
                          data-testid={`graph-${name}`}
                        />
                      )}
                  </FieldButtons>
                  {/* The field for the input variable. */}
                  {info.argumentType === 'UNIT' ? (
                    <Field
                      type='text'
                      label={name}
                      units={info.units}
                      tooltip={tooltip(info)}
                      onChange={async ev => {
                        // Update the fieldsInfo when the input changes.
                        updateAllOnFieldChange({
                          inputs: {
                            ...fieldsInfo.inputs,
                            [name]: { ...fieldsInfo.inputs[name], value: ev.target.value },
                          },
                          outputs: fieldsInfo.outputs,
                        })
                      }}
                      data-testid={`input-${name}`}
                      {...fieldsInfo.inputs[name]}
                    />
                  ) : (
                    <Field
                      type='dropdown'
                      label={name}
                      tooltip={tooltip(info)}
                      onChange={async value => {
                        updateAllOnFieldChange({
                          inputs: {
                            ...fieldsInfo.inputs,
                            [name]: { ...fieldsInfo.inputs[name], value },
                          },
                          outputs: fieldsInfo.outputs,
                        })
                      }}
                      options={info.choices}
                      data-testid={`input-${name}`}
                      {...fieldsInfo.inputs[name]}
                    />
                  )}
                </Fragment>
              )
          )}
        </Column>
        <Separator text='Outputs' breakpoint={breakpoints.md} />
        <Column>
          {Object.entries(formula.arguments).map(
            ([name, info], i) =>
              fieldsInfo.outputs[name] &&
              info.argumentType === 'UNIT' && (
                <Fragment key={i}>
                  {/* The field for the output variable. */}
                  <Field
                    type='text'
                    tooltip={tooltip(info)}
                    label={name}
                    units={info.units}
                    disabled
                    data-testid={`output-${name}`}
                    {...fieldsInfo.outputs[name]}
                  />
                  <FieldButtons>
                    {/* Graph button for outputs */}
                    {!!Object.keys(generateDialogOptions(formula, 'inputs', fieldsInfo)).length && (
                      <FieldButton
                        icon={faChartLine}
                        disabled={!canGraph}
                        // TODO: Add tests for this onClick handler.
                        onClick={() => {
                          // Let the user choose against which input to graph.
                          setDialogType('graph')
                          setDialogOptions('inputs')
                          setDialogSource(name)
                          dialogRef.current?.showModal()
                        }}
                        data-testid={`graph-${name}`}
                      />
                    )}
                    {formula.formulaType === 'PureFormula' && (
                      // Swap button for outputs
                      <FieldButton
                        icon={faRightLeft}
                        // TODO: Add tests for this onClick handler.
                        onClick={async () => {
                          const inputs = Object.keys(fieldsInfo.inputs)
                          if (inputs.length === 1) {
                            // There is only one input. Swap this output with the input.
                            const { [inputs[0]]: inValue, ...freeInputs } = fieldsInfo.inputs
                            const { [name]: outValue, ...freeOutputs } = fieldsInfo.outputs
                            updateAllOnFieldChange({
                              inputs: { ...freeInputs, [name]: outValue },
                              outputs: { ...freeOutputs, [inputs[0]]: inValue },
                            })
                          } else {
                            // There are multiple inputs. Let the user choose which input to swap with.
                            setDialogType('swap')
                            setDialogOptions('inputs')
                            setDialogSource(name)
                            dialogRef.current?.showModal()
                          }
                        }}
                        data-testid={`swap-${name}`}
                      />
                    )}
                  </FieldButtons>
                </Fragment>
              )
          )}
          <CSVContainer>
            <Button
              // TODO: Add tests for this onClick handler.
              onClick={() => {
                // Download a CSV template with the input fields.
                const headers = Object.keys(fieldsInfo.inputs)
                const csvRows = [
                  headers.join(','),
                  Object.values(fieldsInfo.inputs)
                    .map(info => info.value)
                    .join(','),
                ].join('\n')

                downloadFile(formula.name + '.csv', 'text/csv', csvRows)
              }}
            >
              {t('fields.download_csv_template')}
            </Button>
            <CSVInput
              type='file'
              name='inputFile'
              id='inputFile'
              accept='text/csv'
              onChange={ev => {
                // Process the CSV file. This will compute the outputs specified
                // in the CSV file and downloads the CSV file with the outputs.
                if (ev.target.files && ev.target.files[0]) {
                  processInputCSVFile(ev.target.files[0], environment, formula, setOutputError, t)
                }

                // Remove the file from the input field.
                ev.target.value = ''
              }}
            />
            <ProcessCSV as='label' htmlFor='inputFile'>
              {t('fields.upload_csv')}
            </ProcessCSV>
            <CSVTooltip>{t('fields.tooltip')}</CSVTooltip>
          </CSVContainer>
          <AddToHistory data-testid='add-to-history' onClick={() => addToHistory(fieldsInfo, setComputationHistory)}>
            {t('fields.add_to_history')}
          </AddToHistory>
          {outputError && <OutputError>{outputError}</OutputError>}
        </Column>
      </Container>
      {computationHistory.length > 0 && (
        // Computation history table is only shown if there are any values in the history.
        <History>
          <HistoryTitle>{t('fields.computation_history')}</HistoryTitle>
          <Table data-testid='history-table'>
            <thead>
              <tr>
                <th colSpan={Object.keys(fieldsInfo.inputs).length}>
                  {t('formula.input', { count: Object.keys(fieldsInfo.inputs).length })}
                </th>
                <th colSpan={Object.keys(fieldsInfo.outputs).length}>
                  {t('formula.output', { count: Object.keys(fieldsInfo.outputs).length })}
                </th>
                <th></th>
              </tr>
              <tr>
                {Object.keys(fieldsInfo.inputs).map((input, i) => (
                  <td key={i}>{input}</td>
                ))}
                {Object.keys(fieldsInfo.outputs).map((output, i) => (
                  <td key={i}>{output}</td>
                ))}
                <td></td>
              </tr>
            </thead>
            <tbody>
              {computationHistory
                .slice() // To create copy, because otherwise React goes crazy.
                .reverse()
                .slice(0, showMoreComputationHistory ? undefined : COMPUTATION_HISTORY_DEFAULT_MAX_LENGTH)
                .map((computationHistoryItem, i) => (
                  <tr key={i} data-testid={`computation-history-item-${i}`}>
                    {Object.keys(fieldsInfo.inputs).map((input, j) => (
                      <td key={j}>
                        <HistoryValue
                          data-testid={`computation-history-input-${i}-${input}`}
                          onClick={() => {
                            // When clicked on an input value, restore only that input value.
                            const requestedFieldsInfo = fieldsInfo
                            requestedFieldsInfo.inputs[input].value = computationHistoryItem[input]
                            updateAllOnFieldChange(requestedFieldsInfo)
                          }}
                        >
                          {formula.arguments[input].argumentType === 'UNIT'
                            ? truncate(parseFloat(computationHistoryItem[input]), 3)
                            : computationHistoryItem[input]}
                        </HistoryValue>
                      </td>
                    ))}
                    {/* Outputs cannot be clicked, since their fields are read-only. So no onClick here. */}
                    {Object.keys(fieldsInfo.outputs).map((output, j) => (
                      <td key={j}>
                        {formula.arguments[output].argumentType === 'UNIT'
                          ? truncate(parseFloat(computationHistoryItem[output]), 3)
                          : computationHistoryItem[output]}
                      </td>
                    ))}
                    <td>
                      <HistoryTableButtons>
                        <ClickIcon
                          data-testid={`computation-history-restore-button-${i}`}
                          icon={faArrowUpFromBracket}
                          title={t('fields.select_computation_history_item')}
                          onClick={async () => {
                            // When clicked on the restore button, restore all fields.
                            await updateAllOnFieldChange({
                              inputs: Object.fromEntries(
                                Object.entries(computationHistoryItem)
                                  .filter(([k]) => k in fieldsInfo.inputs)
                                  .map(([k, v]) => [k, { value: v }])
                              ),
                              outputs: Object.fromEntries(
                                Object.entries(computationHistoryItem)
                                  .filter(([k]) => k in fieldsInfo.outputs)
                                  .map(([k, v]) => [k, { value: v }])
                              ),
                            })
                          }}
                        />
                        <ClickIcon
                          data-testid={`computation-history-delete-button-${i}`}
                          icon={faTrash}
                          title={t('fields.delete_computation_history_item')}
                          onClick={() => {
                            // When clicked on the delete button, delete the clicked computation history item.
                            setComputationHistory([
                              ...computationHistory.slice(0, computationHistory.length - i - 1),
                              ...computationHistory.slice(computationHistory.length - i),
                            ])
                          }}
                        />
                      </HistoryTableButtons>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <HistoryActions>
            {computationHistory.length > COMPUTATION_HISTORY_DEFAULT_MAX_LENGTH && (
              // Show more/less button only shows when there are more than 5 items in the computation history.
              <Button
                data-testid='show-more-computation-history'
                onClick={() => setShowMoreComputationHistory(!showMoreComputationHistory)}
              >
                {showMoreComputationHistory ? t('fields.show_less') : t('fields.show_more')}
              </Button>
            )}
            <Button
              data-testid='download-computation-history'
              onClick={() => {
                // Download computation history as CSV.
                const inputs = Object.keys(fieldsInfo.inputs)
                const outputs = Object.keys(fieldsInfo.outputs)
                const headers = [...inputs, ...outputs]
                const csvRows = [
                  headers.join(','),
                  ...computationHistory.map(computation =>
                    [...inputs.map(input => computation[input]), ...outputs.map(output => computation[output])].join(
                      ','
                    )
                  ),
                ].join('\n')

                downloadFile(formula.name + '.csv', 'text/csv', csvRows)
              }}
            >
              {t('fields.download_computation_history')}
            </Button>
            {/* Clear computation history button. */}
            <Button data-testid='clear-computation-history' onClick={() => setComputationHistory([])}>
              {t('fields.clear_computation_history')}
            </Button>
          </HistoryActions>
        </History>
      )}
      {/* Multipurpose dialog for choosing either: what to graph; or, what variable to swap with. */}
      <PickDialog
        ref={dialogRef}
        title={
          <>
            {dialogType === 'graph' ? t('formula.dialog.pick_graph_against') : t('formula.dialog.pick_swap_with')}
            &nbsp;
            <DialogVar>{dialogSource}</DialogVar>
          </>
        }
        options={generateDialogOptions(formula, dialogOptions, fieldsInfo)}
        // TODO: Add tests for this onPick handler.
        onPick={async option => {
          // Get the input and output variables that are being swapped or graphed.
          const { input, output } =
            dialogOptions === 'inputs'
              ? { input: option, output: dialogSource }
              : { input: dialogSource, output: option }

          // Update the values of the input and output variables.
          const { [input]: inValue, ...freeInputs } = fieldsInfo.inputs
          const { [output]: outValue, ...freeOutputs } = fieldsInfo.outputs

          // In case the dialog is used to pick what variable to graph against:
          if (dialogType === 'graph') {
            const info = formula.arguments[input]

            // Can only garph against UNIT variables.
            // TODO: Make it clearer to the user that this is the case.
            if (info.argumentType !== 'UNIT') {
              return
            }

            // Prepare the graph parameters.
            const lowerBound = info.physicalRange[0]
            const upperBound = info.physicalRange[1]
            const value = parseFloat(fieldsInfo.inputs[input].value)

            // The graph will be centered around the current input value.
            // It is made sure that the graph will not go out of bounds.
            const [left, right] = computeDefaultBounds(value, [lowerBound, upperBound])

            const params: GraphSettings = {
              min: left.toString(),
              max: right.toString(),
              iterations: '50',
              input,
              output,
              lines: [
                {
                  name: 'Line 1',
                  constants: Object.fromEntries(
                    Object.entries(freeInputs).map(([k, v]) => [k, { type: 'input', value: v.value }])
                  ),
                },
              ],
            }

            // Graph parameters are encoded in the URL.
            const searchParams = new URLSearchParams()
            searchParams.set('params', encodeBase64(JSON.stringify(params)))
            searchParams.set('prevFieldParams', encodeBase64(JSON.stringify(fieldsInfo)))

            const theme = new URLSearchParams(location.search).get('theme')
            if (theme) searchParams.set('theme', theme)

            // Navigate to the graph page using the graph parameters.
            navigate('graph?' + searchParams.toString())
          }

          // In case the dialog is used to pick what variable to swap with:
          else {
            updateAllOnFieldChange({
              inputs: { ...freeInputs, [output]: outValue },
              outputs: { ...freeOutputs, [input]: inValue },
            })
          }
        }}
      />
    </Margin>
  )
}
