import { Globals, useReducedMotion } from '@react-spring/web'
import { useEffect } from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import Root from 'views'
import Embed, { loader as embedLoader } from 'views/Embed'
import Formula, { loader as formulaLoader } from 'views/Formula'
import Fields from 'views/Formula/Fields'
import Graph from 'views/Formula/Graph'
import Home, { loader as homeLoader } from 'views/Home'
import Search, { loader as searchLoader } from 'views/Search'

// The router of the application.
// Defines the hierarchy of the pages in the application.
// Since the application is technically a single-page application, the router
// is responsible for loading the correct view ("page") and its data.
const router = createBrowserRouter(
  [
    {
      element: <Root />,
      children: [
        { path: '/', element: <Home />, loader: homeLoader },
        { path: '/search', element: <Search />, loader: searchLoader },
        {
          path: '/formula/:id',
          id: 'formula',
          element: <Formula />,
          loader: formulaLoader,
          shouldRevalidate: () => false,
          children: [
            { index: true, element: <Fields /> },
            { path: 'graph', element: <Graph /> },
          ],
        },
      ],
    },
    {
      path: '/embed/:id',
      element: <Embed />,
      loader: embedLoader,
      shouldRevalidate: () => false,
      children: [
        { index: true, element: <Fields /> },
        { path: 'graph', element: <Graph /> },
      ],
    },
  ],
  {
    basename: process.env.PUBLIC_URL,
  }
)

/**
 * The main application component.
 * The router is wrapped in a `RouterProvider` to provide the router to the
 * application.
 */
export default function App() {
  // Accessibility: Disable animations if the user prefers reduced motion.
  const prefersReducedMotion = useReducedMotion()

  useEffect(() => {
    Globals.assign({
      skipAnimation: prefersReducedMotion ?? false,
    })

    return () => {
      Globals.assign({
        skipAnimation: false,
      })
    }
  }, [])

  return <RouterProvider router={router} />
}
