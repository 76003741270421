/**
 * Download a file to the user's computer. Uses the popular old school method
 * of programmatically clicking a link with the provided file content, and clicking it.
 *
 * @param fileName The name of the file to download.
 * @param mimeType The mime type of the file to download.
 * @param fileContent The content of the file to download.
 */
export default function downloadFile(fileName: string, mimeType: string, fileContent: string) {
  // Create a blob from the file content
  const blob = new Blob([fileContent], { type: mimeType })

  // Create a link to the blob
  const anchor = window.document.createElement('a')
  anchor.href = window.URL.createObjectURL(blob)
  anchor.download = fileName

  // Need to add it to the document, or it won't be able to be clicked.
  document.body.appendChild(anchor)

  // Click the link. This triggers the download.
  anchor.click()

  // Remove the link from the document
  document.body.removeChild(anchor)
  window.URL.revokeObjectURL(anchor.href)
}
