import breakpoints from 'breakpoints'
import styled from 'styled-components'
import { ThemeColor } from 'themes'

const Separator = styled.hr<{
  text?: string
  breakpoint?: (typeof breakpoints)[keyof typeof breakpoints]
  background?: ThemeColor
}>`
  position: relative;
  border: 1px solid var(${ThemeColor.Tertiary});
  overflow: visible;
  width: 100%;
  transition: border-color 0.2s;

  ${props =>
    props.breakpoint !== undefined
      ? `
    display: none;
    @media ${props.breakpoint} {
      display: block;
      width: 100%;
    }`
      : 'display: block;'}

  &::after {
    /* ${props => props.text !== undefined && `content: ${props.text};`} */
    content: '${props => props.text && props.text}';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2rem;
    padding: 0 0.5rem;
    background: ${props => (props.background ? `var(${props.background})` : `var(${ThemeColor.Primary})`)};
    color: var(${ThemeColor.TextSecondary});
    transition: color 0.2s, background-color 0.2s;
  }
`

export default Separator
