import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logoBlack from 'assets/img/logoBlack.svg'
import logoWhite from 'assets/img/logoWhite.svg'
import Card from 'components/Card'
import SearchBar from 'components/SearchBar'
import config from 'config'
import CondensedFormula from 'domain/entities/CondensedFormula'
import i18n from 'i18n'
import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { LoaderFunction, useLoaderData } from 'react-router-dom'
import styled from 'styled-components'
import { ThemeColor, ThemeContext } from 'themes'

const Hero = styled.div`
  width: 100%;
  background: var(${ThemeColor.Secondary});
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  gap: 20px;
  padding: 140px 20px 20px 20px;
  transition: background-color 0.2s;
`

const Logo = styled.img`
  max-height: 35vh;
  max-width: 1100px;
`

const Slogan = styled.div`
  white-space: pre-line;
  font-size: 1.5rem;
  text-align: center;
  color: var(${ThemeColor.TextSecondary});
  transition: color 0.2s;
`

const Search = styled(SearchBar)`
  appearance: none;
  max-width: 1100px;
  width: 100%;
  font-size: 3rem;
  border-radius: 18px;
  padding: 2px 18px 0;
  background: var(${ThemeColor.Primary});
  transition: background-color 0.2s;

  & > input {
    appearance: none;
    height: 100%;
    width: 100%;
    background: transparent;
    color: var(${ThemeColor.Text});
    border: none;
    transition: color 0.2s;
    &::placeholder {
      color: var(${ThemeColor.TextSecondary});
      transition: color 0.2s;
    }

    &:focus {
      outline: none;
    }
  }
`

const Scroller = styled.div`
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: center;
  color: var(${ThemeColor.Text});
  transition: color 0.2s;
`

const Formulas = styled.div`
  max-width: 1100px;
  width: 100%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`

const Separator = styled.hr`
  margin: 0;
`

/**
 * Loads the condensed list of formulas from the API.
 *
 * @returns The list of formulas and keywords.
 */
export const loader = (async () => {
  // Fetch the list of formulas from the API.
  const fetched = await (await fetch(`${config.apiUrl}/formula`)).json()
  if (!Array.isArray(fetched)) {
    throw SyntaxError(i18n.t('error.formula_list_expected'))
  }

  // Extract the keywords and formulas from the list.
  const keywords: { [key: string]: CondensedFormula[] } = {}
  const formulas: CondensedFormula[] = []

  for (const formula of fetched.map(el => CondensedFormula.fromJson(el))) {
    if (formula.keywords.length === 0) formulas.push(formula)
    else {
      for (const keyword of formula.keywords) {
        if (keyword in keywords) keywords[keyword].push(formula)
        else keywords[keyword] = [formula]
      }
    }
  }
  return { keywords, formulas }
}) satisfies LoaderFunction

/**
 * The Home view offers quick navigation to the formulas. It offers two ways to
 * navigate to a formula: by searching for it, or by browsing the labels.
 */
export default function Home() {
  const { keywords, formulas } = useLoaderData() as LoaderData<typeof loader>
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()

  useEffect(() => {
    document.title = 'PyBox'
  }, [])

  return (
    <>
      <Hero>
        <Logo src={theme === 'light' ? logoBlack : logoWhite} alt={t('logo')} />
        <Slogan>{t('slogan')}</Slogan>
        <Scroller>
          <FontAwesomeIcon icon={faChevronDown} size='2xl' />
        </Scroller>
        <Search />
      </Hero>

      <Formulas>
        {Object.keys(keywords)
          .sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1))
          .map(keyword => (
            <Card
              ident={keyword}
              name={(() => {
                // Return the keyword in nice human-readable format.
                const tmp = keyword.replaceAll('-', ' ').trim()
                return tmp.charAt(0).toLocaleUpperCase() + tmp.slice(1)
              })()}
              key={keyword}
              subcards={keywords[keyword]}
            />
          ))}

        {Object.keys(keywords).length > 0 && formulas.length > 0 && <Separator data-testid='separator' />}

        {formulas.map(formula => (
          <Card key={formula.ident} ident={formula.ident} name={formula.name} />
        ))}
      </Formulas>
    </>
  )
}
