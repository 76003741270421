/**
 * A test case is a set of inputs and outputs that are defined for automated
 * testing in the contributor's Jupyter notebook.
 */
type TestCase = {
  inputs: { [key: string]: string }
  outputs: { [key: string]: string }[]
}

namespace TestCase {
  /**
   * Checks if the given JSON object is a valid TestCase.
   * @param json The JSON object to check.
   * @throws SyntaxError if the given JSON object is not a valid TestCase.
   */
  export function isTestCase(json: unknown): asserts json is TestCase {
    if (typeof json !== 'object' || json === null) {
      throw SyntaxError('bad TestCase format: not an object')
    }

    if (!('inputs' in json)) {
      throw SyntaxError('bad TestCase format: missing property inputs')
    }

    if (typeof json.inputs !== 'object' || json.inputs === null) {
      throw SyntaxError('bad TestCase format: property inputs is not an object')
    }

    Object.entries(json.inputs).forEach(([key, value]) => {
      if (typeof value !== 'string') {
        throw SyntaxError(`bad TestCase format: input ${key} is a ${typeof value}; expected a string`)
      }
    })

    if (!('outputs' in json)) {
      throw SyntaxError('bad TestCase format: missing property outputs')
    }

    if (!Array.isArray(json.outputs)) {
      throw SyntaxError('bad TestCase format: property outputs is not an array')
    }

    json.outputs.forEach(output => {
      if (typeof output !== 'object' || output === null) {
        throw SyntaxError('bad TestCase format: outputs are not an object')
      }

      Object.entries(output).forEach(([key, value]) => {
        if (typeof value !== 'string') {
          throw SyntaxError(`bad TestCase format: output ${key} is a ${typeof value}; expected a string`)
        }
      })
    })
  }

  /**
   * Creates a TestCase from the given JSON object.
   * @param json The JSON object to create the TestCase from.
   * @throws SyntaxError if the given JSON object is not a valid TestCase.
   * @returns The created TestCase.
   */
  export function fromJson(json: unknown): TestCase {
    isTestCase(json)

    return { ...json }
  }
}

export default TestCase
