/**
 * Takes a string and removes all trailing zeroes.
 * Used to get rid of trailing zeroes after the comma in a decimal number.
 *
 * @param str The string to trim.
 * @returns The trimmed string.
 *
 * @example '123abc45' -> '123abc45'
 * @example '12xyz300' -> '12xyz3'
 * @example '0001' -> '0001'
 * @example '00000' -> ''
 */
const trimZeroes = (str: string) => {
  let i = str.length - 1
  while (str[i] === '0') i--
  return str.substring(0, i + 1)
}

/**
 * Makes a wonky number look not so wonky.
 *
 * Forces a maximum number of digits after the decimal point.
 * Supports scientific notation.
 * Used to make numbers look nice in the UI.
 *
 * @param num The number to truncate.
 * @param digits The number of digits after the decimal point.
 * @returns The truncated number.
 */
export const truncate = (num: number, digits: number) => {
  if (num == 0) {
    // Otherwise, we'd get 0e+0 which looks kinda meh.
    return '0'
  }

  // Below this bound, `toFixed()` would return 0.0000, so we need to call `toExponential()`.
  const lowerBound = 1 / 10 ** digits / 2
  // Above this bound, there will be too many digits before the decimal point, so we need to call `toExponential()`.
  const upperBound = 10 ** Math.max(3, digits + 1)

  // Represent the number as string, using exponential notation if it's too small or too large.
  const asFixed =
    (num < lowerBound && num > -lowerBound) || num > upperBound || num < -upperBound
      ? num.toExponential(digits)
      : num.toFixed(digits)

  // Take the following examples, with digits = 2:
  // 1) 12.3456789         2) 12.3456789e+29         3) 12.3456789e-29

  // Get the part before the decimal:
  // 1) 12                 2) 12                     3) 12
  const partBeforeDecimal = asFixed.split('.')[0]

  // Get the part after the decimal, but excluding the exponent, if there is any:
  // 1) 3456789            2) 3456789                4) 3456789
  const partAfterDecimal = trimZeroes(asFixed.split('.')[1]?.split('e')[0] ?? '')

  // Set the decimal point, only if there should be one.
  // We should NOT have a decimal point if digits == 0, or if there are no digits after the decimal.
  const decimal = partAfterDecimal.length > 0 && digits > 0 ? '.' : ''

  // Get the exponent, if there is any:
  // 1) undefined         2) 29                     3) -29
  const exponent = asFixed.split('.')[1]?.split('e')[1]

  // Stitch it all together:

  if (exponent) {
    //                    2) 12.3456789e+29         3) 12.3456789e-29
    return `${partBeforeDecimal}${decimal}${partAfterDecimal.substring(0, digits)}e${exponent}`
  }

  // 1) 12.3456789
  return `${partBeforeDecimal}${decimal}${partAfterDecimal.substring(0, digits)}`
}

/**
 * Parses a number from the JSON provided by the back-end application.
 * @param num The number to parse. Can be a number, 'Infinity', or '-Infinity'.
 * @returns The parsed number.
 */
export const parseNumber = (num: number | 'Infinity' | '-Infinity') => {
  return num === 'Infinity' ? Infinity : num === '-Infinity' ? -Infinity : num
}

/**
 * Computes default lower and upper bounds for a given number.
 * This is kind of guessing work. This function is used to compute somewhat
 * plausible bounds for a number that we have no idea about what range it
 * might have.
 *
 * @param num The number to compute the bounds for.
 * @param physicalRange The physical range of the number.
 * @returns The lower and upper bounds.
 */
export const computeDefaultBounds = (num: number, physicalRange: [number, number]) => {
  // These are the critical values. We cannot go beyond these.
  const lo = physicalRange[0]
  const hi = physicalRange[1]

  // Use a heuristic to compute plausible bounds.
  const left = Math.max(lo, num - 0.5 * Math.min(hi - lo, num))
  const right = Math.min(hi, num + 0.5 * Math.min(hi - lo, num))

  return [left, right]
}
