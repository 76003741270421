/**
 * Contains the value of a field, and optionally an error or warning message.
 */
export type FieldContent = { value: string; error?: string; warning?: string }

/**
 * Contains the values of the inputs and outputs of a formula.
 * The values may contain error or warning messages.
 */
export type FieldsInfo = { inputs: { [name: string]: FieldContent }; outputs: { [name: string]: FieldContent } }

/**
 * Checks if the given JSON object is a valid FieldContent.
 * @param json The JSON object to check.
 * @throws SyntaxError if the given JSON object is not a valid FieldContent.
 */
function isFieldContent(json: unknown): asserts json is FieldContent {
  if (typeof json !== 'object' || json === null) {
    throw SyntaxError('bad FieldInfo format: not an object')
  }

  if (!('value' in json)) {
    throw SyntaxError('bad FieldInfo format: missing property value')
  }

  if (typeof json.value !== 'string') {
    throw SyntaxError('bad FieldInfo format: value is not a string')
  }

  if ('error' in json && typeof json.error !== 'string') {
    throw SyntaxError('bad FieldInfo format: error is not a string')
  }

  if ('warning' in json && typeof json.warning !== 'string') {
    throw SyntaxError('bad FieldInfo format: warning is not a string')
  }
}

namespace FieldsInfo {
  /**
   * Checks if the given JSON object is a valid FieldsInfo.
   * @param json The JSON object to check.
   * @throws SyntaxError if the given JSON object is not a valid FieldsInfo.
   */
  export function isFieldsInfo(json: unknown): asserts json is FieldsInfo {
    if (typeof json !== 'object' || json === null) {
      throw SyntaxError('bad FieldsInfo format: not an object')
    }

    if (!('inputs' in json)) {
      throw SyntaxError('bad FieldsInfo format: missing property inputs')
    }

    if (typeof json.inputs !== 'object' || json.inputs === null) {
      throw SyntaxError('bad FieldInfo inputs format: not an object')
    }

    if (!('outputs' in json)) {
      throw SyntaxError('bad FieldsInfo format: missing property outputs')
    }

    if (typeof json.outputs !== 'object' || json.outputs === null) {
      throw SyntaxError('bad FieldInfo outputs format: not an object')
    }

    Object.entries(json.inputs).forEach((value, _) => isFieldContent(value[1]))
    Object.entries(json.outputs).forEach((value, _) => isFieldContent(value[1]))
  }

  /**
   * Creates a FieldsInfo from the given JSON object.
   * @param json The JSON object to load from.
   * @throws SyntaxError if the given JSON object is not a valid FieldsInfo.
   * @returns Creates loaded FieldsInfo.
   */
  export function fromJson(json: unknown): FieldsInfo {
    isFieldsInfo(json)

    return json
  }
}

export default FieldsInfo
