import ValueType from 'domain/entities/ValueType'
import i18n from 'i18n'

/**
 * Returns the tooltip text for a given ValueType.
 * The tooltip text includes the description of the ValueType and its physical and tentative ranges.
 *
 * @param valueType The ValueType to get the tooltip text for.
 * @returns The tooltip text for the given ValueType.
 */
export const tooltip = (valueType: ValueType) => {
  switch (valueType.argumentType) {
    case 'UNIT':
      return (
        `${valueType.description}\n${i18n.t('formula.physical_range', {
          range: `(${valueType.physicalRange[0]}, ${valueType.physicalRange[1]})`,
        })}` +
        `${
          valueType.tentativeRange
            ? `\n${i18n.t('formula.tentative_range', {
                range: `(${valueType.tentativeRange[0]}, ${valueType.tentativeRange[1]})`,
              })}`
            : ''
        }`
      )
  }
}
