import { createContext } from 'react'
import { a11yDark as codeDark, docco as codeLight } from 'react-syntax-highlighter/dist/esm/styles/hljs'

/**
 * References to the CSS variable names used for the theme colors.
 */
export const enum ThemeColor {
  Primary = '--color-primary',
  Secondary = '--color-secondary',
  Tertiary = '--color-tertiary',
  Text = '--color-text',
  TextSecondary = '--color-textSecondary',
  Accent = '--color-accent',
  Error = '--color-error',
  Warning = '--color-warning',
}

export type Theme = 'light' | 'dark'

export type ColorTheme = Omit<Record<ThemeColor, string>, ThemeColor.Accent | ThemeColor.Error | ThemeColor.Warning> &
  Partial<Record<ThemeColor, string>>

/**
 * The color themes used by the application.
 * The keys are the names of the themes.
 * The values are the color themes.
 */
const themes: Record<Theme, ColorTheme> = {
  light: {
    [ThemeColor.Primary]: '#fff',
    [ThemeColor.Secondary]: '#ecf0f9',
    [ThemeColor.Tertiary]: '#e0e5f5',
    [ThemeColor.Text]: '#000',
    [ThemeColor.TextSecondary]: '#4d5fa3',
    [ThemeColor.Accent]: '#227aff',
  },
  dark: {
    [ThemeColor.Primary]: '#1f2032',
    [ThemeColor.Secondary]: '#35354d',
    [ThemeColor.Tertiary]: '#444460',
    [ThemeColor.Text]: '#fff',
    [ThemeColor.TextSecondary]: '#afaff4',
    [ThemeColor.Accent]: '#00d0ff',
  },
}

/**
 * The code themes used by the application. Used for the syntax highlighting.
 * The keys are the names of the themes.
 * The values are the code themes.
 */
export const codeThemes: Record<Theme, { [key: string]: React.CSSProperties }> = {
  light: codeLight,
  dark: codeDark,
}

/**
 * Initialize the theme context with the user's preferred color scheme.
 */
export const ThemeContext = createContext<keyof typeof themes>(
  window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
)

export default themes
